import paper from 'paper'
import { setup } from 'paper/dist/paper-core';
import gsap from 'gsap'


export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getRandomColor() {
    return this.colors[this.getRandomInt(0, this.colors.length-1)];
  }

  init() {

    this.audio = [
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628836/music-notes/2_qs8tjr.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628835/music-notes/3_krcs13.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628855/music-notes/4_kfxw3c.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628841/music-notes/1_qal3mj.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628838/music-notes/5_cuu7wk.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628843/music-notes/6_sztt6o.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628839/music-notes/7_yvsbqv.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628842/music-notes/8_i3xmsn.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628841/music-notes/9_klziaf.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1625628841/music-notes/10_dmv1rg.mp3')
    ];

    this.canvas = document.getElementById('paperCanvas');
    this.canvas.style.height = (parseInt(this.canvas.clientWidth)) + "px";
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];

    this.iStroke = true;

    this.numberOfCircles = 10;
    this.step = 24;
    this.opacityStep = 0.08;
    this.noiseStep = 10;
    this.strokeWidth = 5;

    this.hitOptions = {
      segments: true,
      stroke: true,
      fill: false,
      tolerance: 8,
    };

    paper.setup(this.canvas);
    this.setup();
    paper.view.draw();
  }

  drawInitialCircle() {
    for(let i=0;i<this.numberOfCircles;i++) {
      this.myCircle[i] = new paper.Path.RegularPolygon(new paper.Point(this.canvas.clientWidth/2, this.canvas.clientHeight/2), 50, (this.step*i));
      
      if(this.iStroke) {
        this.myCircle[i].strokeWidth = this.strokeWidth;
        this.myCircle[i].strokeColor = '#000';
      }
      else {
        this.myCircle[i].fillColor = '#000';
        this.myCircle[i].opacity = this.opacityStep;
      }

      this.myCircle[i].smooth({
        method: 'geometric',
        factor: 1
      });
    }
  }

  checkWhichString(area) {
    var result = 0;
    var diff = 10000;

    for(let i=1; i<this.numberOfCircles;i++) {
      var newDiff = Math.abs(area - (Math.PI*(i*this.step)*(i*this.step)));
      
      if(newDiff < diff)
        result = i;
    }

    return result;
  }

  bindMouseEvents() {
    paper.view.onMouseMove = (event) => {

      var hitResult = paper.project.activeLayer.hitTestAll(event.point, this.hitOptions);
      
      for(let i=0;i<this.numberOfCircles;i++) {
        this.myCircle[i].strokeColor = '#000';
      }
      
      hitResult.forEach((result) => {
        result.item.strokeColor = 'red';
        // result.item.strokeWidth = 4;
      });
    }

    paper.view.onClick = (event) => {

      var hitResult = paper.project.activeLayer.hitTestAll(event.point, this.hitOptions);
      
      hitResult.forEach((result, ResultIndex) => {
        
        var string = this.checkWhichString(result.item.area);
        
        this.audio[string%10].currentTime=2;
        this.audio[string%10].play();
        
        result.item.segments.forEach((seg, index) => {
          if(seg.point.getDistance(event.point) < 50) {

            // console.log(ResultIndex);

            var tl = gsap.timeline();
            var x = seg.point.x;
            var y = seg.point.y;

            tl.to(seg.point, {
              x: x+this.getRandomInt(this.noiseStep*-1,this.noiseStep),
              y: y+this.getRandomInt(this.noiseStep*-1,this.noiseStep),
              duration: 0.5,
              ease: 'power3.inOut'
            });
            tl.to(seg.point, {
              x: x,
              y: y,
              duration: 2,
              ease: 'power3.out'
            });
          }          
        });
      });

    }

  }

  setup() {

    this.myCircle = [];
    this.drawInitialCircle();
    this.bindMouseEvents();
    
  }


  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

}