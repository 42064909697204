import paper from 'paper'
import gsap from 'gsap'
import { Point, setup } from 'paper/dist/paper-core';

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');
    this.canvas.style.height = this.canvas.clientWidth+"px";

    this.tl = gsap.timeline();
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];

    paper.setup(this.canvas);
    this.setup();
    paper.view.draw();
  }

  setup() {

    paper.view.onFrame = (event) => {
      var time = event.time;

      var width = ((event.time%4)/4)*paper.view.size.width;

      var rect = new paper.Path.Rectangle(new paper.Point(0,0), new paper.Size(width, 5));
      rect.fillColor = '#000';

    };

    this.combos = [
      { curve: false,
        line: false
      },
      { curve: false,
        line: false
      },
      { curve: false,
        line: false
      },
      { curve: false,
        line: false
      },
      { curve: true,
        line: false
      },
      { curve: true,
        line: false
      }
    ];

    this.corners = [
      new paper.Point(200, 200),
      new paper.Point(200, 400),
      new paper.Point(400, 200),
      new paper.Point(400, 400),
    ];

    if(paper.view.size.width < 500) {

      var width = paper.view.size.width;
      
      this.corners = [
        new paper.Point(150, 150),
        new paper.Point(150, width-150),
        new paper.Point(width-150, 150),
        new paper.Point(width-150, width-150),
      ];
    }

    this.corners.forEach((corner) => {
      var point = new paper.Path.Circle(corner,1);
      point.fillColor = '#000';
    });

    var start = null;
    var end = null;
    var flag = true;

    while(flag == true) {
      if(start == null) {
        start = this.getRandomInt(0,3);
      }

      end = this.getRandomInt(0,3);

      while(start == end)
        end = this.getRandomInt(0,3);      

      // setTimeout(() => {
        flag = this.checkDraw(start, end);
      // },100);

      if(flag == false) {
        for(let i = 0 ; i<4;i++) {

          if(start != i) {
            flag = this.checkDraw(start, i);
            if(flag == true) {
              end = i;
              break;
            }
          }
        }
      }

      start = end;
      
    }

  }

  checkCombo(x, start, end) {
    
    if(this.getRandomInt(0,1)) {
      if(x.curve == false) {
        this.drawCurve(start, end);
        x.curve = true;
        return true;
      }
      else if(x.line == false) {
        this.drawLine(start, end);
        // paper.view.attach('frame', this.drawLine);
        x.line = true;
        return true;
      }
    }
    else {
      if(x.line == false) {
        x.line = true;

        this.drawLine(start, end);
        return true;
      }
      else if(x.curve == false) {
        this.drawCurve(start, end);
        x.curve = true;
        return true;
      }
    }

    return false;
  }

  checkDraw(start, end) {
    if((start == 0 && end == 2)
        || (start == 2 && end == 0)) {

      return this.checkCombo(this.combos[0], start, end);
    }

    if((start == 2 && end == 3)
      || (start == 3 && end == 2)) {
        return this.checkCombo(this.combos[1], start, end);
    }

    if((start == 1 && end == 3)
      || (start == 3 && end == 1)) {
        return this.checkCombo(this.combos[2], start, end);
    }

    if((start == 0 && end == 1)
      || (start == 1 && end == 0)) {
        return this.checkCombo(this.combos[3], start, end);
    }

    if((start == 0 && end == 3)
      || (start == 3 && end == 0)) {
        return this.checkCombo(this.combos[4], start, end);
    }

    if((start == 2 && end == 1)
      || (start == 1 && end == 2)) {
        return this.checkCombo(this.combos[5], start, end);
    }
  }

  drawCurve(start, end) {

    var setAngle = -90;

    if((start == 0 && end == 2)
      || (start == 2 && end == 0)) {
        setAngle = -90;
    }  

    if((start == 2 && end == 3)
      || (start == 3 && end == 2)) {
        setAngle = 0;
    }

    if((start == 1 && end == 3)
      || (start == 3 && end == 1)) {
        setAngle = 90;
    }

    if((start == 0 && end == 1)
      || (start == 1 && end == 0)) {
        setAngle = -180;
    }

    let vector = new paper.Point({
      angle: 0,
      length: 150
    });
    
    let line = new paper.Path({
        segments: [
        [this.corners[start], null,vector.rotate(setAngle)],
        [this.corners[end], vector.rotate(setAngle), null]
        ],
      strokeColor: 'black',
      strokeWidth: 2,
      dashArray: [400, 400],
      dashOffset: 0,
      opacity: .4,
      closed: false,
      // fullySelected: true,
    });

    this.tl.from(line, {
      dashOffset: 400,
      duration: .4
    });

  }

  drawLine(start, end) {
    
    var line = new paper.Path();
    line.strokeColor = '#000';
    line.strokeWidth = 2;
    line.dashArray= [400, 400];
    line.dashOffset= 0;
    line.opacity = .4;

    var startPoint = this.corners[start];
    var endPoint = this.corners[end];

    line.add(startPoint);
    line.add(endPoint);

    for(let i=0;i<5;i++) {
      var x = startPoint.x + ((endPoint.x - startPoint.x)/5);
      var y = startPoint.x + ((endPoint.x - startPoint.x)/5);
      // line.insert(1, new Point());
    }

    this.tl.from(line, {
      dashOffset: 400,
      ease: 'power1.out',
      duration: 0.4
    });

  }


  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

}