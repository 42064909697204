import './App.scss';
import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Playground from './Playground'
import GridExplorer from './GridExplorer'
import Home from './Home'
import ProjectDetails from './ProjectDetails'
import Work from './Work'
import onEnter from './util.js'
import { gsap } from 'gsap';

function App() {

  useEffect(() => {
    document.querySelector('body').style.opacity = 1;

    gsap.from('.loading-screen', {
      height: '100vh',
      duration: 1,
      delay: 1,
      ease: 'power3.inOut'
    });

    gsap.from('header', {
      top: -20,
      opacity: 0,
      duration: 0.5,
      delay: 1.8
    });

    onEnter();
  });

  return (
    <div className="App">
    <div className="loading-screen"></div>
    
      <Route
        render={({ location }) => (
          <AnimatePresence initial={false} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
            <Route 
              path='/' 
              exact 
              render={() => 
                <Home />
              } 
            />
            <Route 
              path='/design/:category/:id' 
              exact 
              render={() => 
                <ProjectDetails />
              } 
            />
            <Route 
              path='/work/:category' 
              exact 
              render={() => 
                <Work />
              } 
           />
           <Route 
              path='/playground/:id' 
              exact 
              render={() => 
                <Playground />
              } 
            />
            <Route 
              path='/playground/' 
              exact 
              render={() => 
                <Playground />
              } 
            />
          </Switch>
        </AnimatePresence>
      )} />
      <Route 
        path='/grid-explorer/' 
        exact 
        render={() => 
          <GridExplorer />
        } 
      />
    </div>
  );
}

export default App;
