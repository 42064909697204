import '../styles/PlayGroundLinks.scss';
import { NavLink } from "react-router-dom";

function PlayGroundLinks() {

  return (
    <div>
      <div className="blob-top">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </div>

      <div className="playgroundLinks" id="playground">
        <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1622292727/waves_blkzhg.svg" alt="" className="waves"/>
        <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1622292727/gold-shape_g8vta5.svg" alt="" className="gold"/>
        <canvas id="rocketship"></canvas>
        <div className="container">
          <h2>You and me on the same website? What is this? A crossover episode?</h2>
          <h3>playground</h3>
          <ul>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/character-stretch">character stretch</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/hindi-characters">hindi letters</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/color-beats">color beats</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/flow-field">flow field</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/strings">strings</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/wave-jungle">wave jungle</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/close-to-home">close to home</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/letter-fall">letter fall</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/impossible-puzzle">impossible puzzle</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/entanglements">entanglements</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/dots-and-dashes">dots & dashes</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/generative-rocks">generative rocks</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/music-box">Music Box</NavLink></li>
            <li><NavLink target="_blank" rel="noopener noreferrer" to="/playground/anti-matter">Anti-Matter</NavLink></li>
          </ul>
          <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1622292727/pink-star_ejsmca.svg" alt="" className="pink-star desktop-only"/>
        </div>
        
      </div>

      <div className="blob-bottom">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </div>
    </div>
  );
}

export default PlayGroundLinks;