import '../styles/playground/antiMatter.scss'
import Art from '../three-js-projects/anti-matter/art.js';
import { useEffect } from 'react';
import {gsap} from 'gsap';

function AntiMatter() {

  useEffect(() => {

    Art.init();
    
    gsap.from('#antimatter', {
      scale: 0.7,
      duration: 1
    })
    
    const options = [
      [2.3,1.4,0.9, "linear-gradient(to right, #f7797d, #FBD786, #C6FFDD)"],
      [1.2,0.0,0.0, "linear-gradient(90deg, #870000 0%, #190A05 100%)"],
      [1.1,1.2,2.6, "linear-gradient(90deg, #70E1F5 0%, #FFD194 100%)"],
      [2.9,1,0, "linear-gradient(90deg, #E65C00 0%, #F9D423 100%)"],
      [0.3,1.3,0.6, "linear-gradient(90deg, #16A085 0%, #F4D03F 100%)"]];
    
    const container = document.querySelector('.antimatter-container');
    
    const optionsList = document.querySelectorAll('.options li');
    
    optionsList.forEach(item => {
      item.addEventListener('click', event => {

        gsap.to(Art.material.uniforms.uxColor, {
          value: options[item.dataset.pos][0],
          duration: 1
        });
        gsap.to(Art.material.uniforms.uyColor, {
          value: options[item.dataset.pos][1],
          duration: 1
        });
        gsap.to(Art.material.uniforms.uzColor, {
          value: options[item.dataset.pos][2],
          duration: 1
        });
    
        container.style.background = options[item.dataset.pos][3];

      });
    });

    return () => {
      Art.obj.renderer.dispose();
    };
  })

  return (
    <div className="antimatter-container art-container">
      <div className="art">
        <canvas id="antimatter" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / anti-matter</h3>
        <p>None conforming material floating in space.<br></br><br></br>
        Built using THREE.JS and some shader manipulation<br></br><br></br>
        Try some of the variations below.
        </p>
        <ul className="options">
          <li data-pos="1"><button>Mars</button></li>
          <li data-pos="2"><button>Breeze</button></li>
          <li data-pos="3"><button>Fire</button></li>
          <li data-pos="4"><button>Jelousy</button></li>
          <li data-pos="0"><button>Glow</button></li>
        </ul>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AntiMatter;
