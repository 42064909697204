import '../styles/playground/impossible-puzzle.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/impossible-puzzle/art.js';

function ImpossiblePuzzle() {

  var animator = null;

  useEffect(() => {

    document.title = 'The Impossible Puzzle | Karan Mhatre';

    Art.init();
  
    animator= setInterval(() => {
      Art.cleanCanvas();
      Art.setup();
    }, 4000);
    
    return () => {
      Art.cleanCanvas();
      clearInterval(animator);
    };

  })

  return (
    <div className="impossible-puzzle-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / the impossible puzzle</h3>
        <p>The impossbile puzzle is very kid's most challenging childhood memory. <br></br><br></br>Our program will nervewrackingly, painstackingly continue in their endevour to solve the puzzle, and fail (but don't tell it that, else it may stop trying).</p> 
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>  
  );
}

export default ImpossiblePuzzle;
