import '../styles/playground/waves.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/waves/art.js';

function Waves() {

  useEffect(() => {

    document.title = 'Wave Jungle | Karan Mhatre';

    Art.init();

    document.getElementById('clearBtn').addEventListener('click', () => {
      Art.cleanCanvas();
      Art.addBg();
    });

    document.getElementById('downloadBtn').addEventListener('click', () => {
      Art.downloadImage();
    });
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="waves-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / wave jungle</h3>
        <p>Release some wild waves on the canvas and watch them dance.<br></br><br></br>
        Click to create a new wave. Each wave will be a random color, length, will have a random number of peaks.
        </p>
        <ul className="options">
          <li><button id="clearBtn">Clear</button></li>
          <li><button id="downloadBtn">Download</button></li>
        </ul>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Waves;
