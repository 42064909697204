import '../styles/playground/shyCircles.scss'
import Art from '../three-js-projects/shy-circles/art.js';
import { useEffect } from 'react';

function ShyCircles() {

  useEffect(() => {
    Art.init();

    return () => {
      Art.removeAllElements();
    };
  })

  return (
    <div className="shy-circles-container">
      <canvas id="shyCircles"></canvas>
    </div>
  );
}

export default ShyCircles;
