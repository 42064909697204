import './App.scss';
import ReactHtmlParser from 'react-html-parser';

// Utils
import { useEffect } from 'react';

function DesignItem(props) {

  useEffect(() => {

  });

  return (
    <div key={props.id} className="content-item">
        {props.type === "img" && 
          <div className="image-content">
            <img src={ "https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_2400"+props.src } alt=""/>  
            <p>{props.text}</p>
          </div>
        }

        {props.type === "vid" && 
        <div className="image-content">
          <video autoPlay muted loop>
            <source src={props.src} type="video/mp4" />
          </video>
          <p>{props.text}</p>
        </div>
        }

        {props.type === "text" && 
          <div className="text-content">
            <h3>{props.heading}</h3>
            <div className="content">{ ReactHtmlParser(props.text) }</div>
          </div>
        }

        {props.type === "full-html" && 
          <div className="text-content">
            { ReactHtmlParser(props.src) }
          </div>
        }
    </div>
  );
}

export default DesignItem;