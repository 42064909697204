import '../styles/playground/dots.scss'
import paper from 'paper'
import { useEffect } from 'react';
import SCRIPT from '../three-js-projects/dots-and-dashes/script.js';

function Dots() {

  useEffect(() => {

    document.title = 'Dots and Dashes | Karan Mhatre';

    SCRIPT.init();

    document.getElementById('genBtn').addEventListener('click', () => {
      SCRIPT.isAnimate = false;
      SCRIPT.isAutoPlay = false;
      SCRIPT.audio.pause();
      SCRIPT.render();
    });

    document.getElementById('downloadBtn').addEventListener('click', () => {
      SCRIPT.downloadImage();
    });

    
    
    document.getElementById('animateBtn').addEventListener('click', () => {
      
      var btn = document.getElementById('animateBtn');
      
      btn.classList.toggle('active'); 

      if(btn.classList.contains("active"))
        btn.innerHTML = "Stop";
      else
        btn.innerHTML = "Animate";
      
      SCRIPT.audio.volume = 0.2;
      
      if(SCRIPT.isAnimate) {
        SCRIPT.isAnimate = false;
        SCRIPT.audio.pause();
      }
      else {
        SCRIPT.audio.play();
        SCRIPT.isAnimate = true;
        SCRIPT.isAutoPlay = false;
        
        SCRIPT.cleanCanvas();
        SCRIPT.count.x = 2;
        SCRIPT.count.y = 1;
        SCRIPT.tick();
      }
    });
    
    return () => {
      SCRIPT.cleanCanvas();
      SCRIPT.isAnimate = false;
      SCRIPT.isAutoPlay = false;
      SCRIPT.audio.pause();
    };

  })

  return (
    <div className="dots-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / dots & dashes</h3>
        <p>Unexpected obstacles and beautiful patterns cover the canvas in elegent chaos.<br></br><br></br>
        Navigate line by line, and draw either a dot, a circle, or a rounded rectangle.<br></br><br></br>
        Animate to see it unfold<br></br><br></br>
        Each piece is unique and new.
        </p>
        <ul className="options">
          <li><button id="animateBtn">Animate</button></li>
          <li><button id="genBtn">Generate</button></li>   
          <li><button id="downloadBtn">Download</button></li>        
        </ul>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dots;
