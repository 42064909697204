import paper from 'paper'
import { setup } from 'paper/dist/paper-core';
import gui from 'dat.gui'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  init() {
    var canvas = document.getElementById('paperCanvas');

    canvas.style.height = canvas.offsetWidth + "px";
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];
    this.gap = 50;
    this.height = 130;
    this.stick = 0.5;
    this.shadow = 90;

    this.controls = new gui.GUI({
      closed: false,
      closeOnTop: false
    });
    
    const customContainer = document.querySelector('.gui-controls');
    customContainer.appendChild(this.controls.domElement);

    this.controls.add(this, 'gap', 50, 500).listen();
    this.controls.add(this, 'height', 50, 500).listen();
    this.controls.add(this, 'stick', 0.1, 1, 0.1).listen();
    this.controls.add(this, 'shadow', 0, 300, 1).listen();

    paper.setup(canvas);
    this.setup();
    paper.view.draw();    

    this.draw();
  }

  setup() {

    paper.view.onKeyDown = (e) => {

      switch(e.character) {
        case '1':
          if(this.gap >= 40) {
            this.gap = this.gap-20;
          }
          else {
            this.gap = 30;
          }
          break;

        case '2':
            if(this.gap <= 490) {
              this.gap = this.gap+20;
            }
            else {
              this.gap = 500;
            }
            break;
    
        case '3':
          if(this.height >= 40) {
            this.height = this.height-20;
          }
          else {
            this.height = 30;
          }
          break;

        case '4':
          if(this.height <= 490) {
            this.height = this.height+20;
          }
          else {
            this.height = 500;
          }
          break;

        case '5':
          if(this.stick >= 0.2) {
            this.stick = this.stick-0.1;
          }
          else {
            this.stick = 0.1;
          }
          break;

        case '6':
            if(this.stick <= 0.9) {
              this.stick = this.stick+0.1;
            }
            else {
              this.stick = 1;
            }
            break;
        
        case '7':
          if(this.shadow >= 0) {
            this.shadow = this.shadow-10;
          }
          else {
            this.shadow = 0;
          }
          break;

        case '8':
          if(this.shadow <= 290) {
            this.shadow = this.shadow+10;
          }
          else {
            this.shadow = 300;
          }
          break;
      }
      
      console.log(e);
    }

  }

  draw() {
    paper.view.onFrame = (event) => {
      this.cleanCanvas();

      var time = event.time;

      var viewCenter = paper.view.size.width/2;

      var path = new paper.Path([viewCenter-(this.gap/2), viewCenter+(this.height/2)]);
      path.lineTo([viewCenter-(this.gap/2), viewCenter-(this.height/2)]);
      path.strokeJoin = 'round';
      path.curveTo([viewCenter, 200-(this.height/2)], [viewCenter+(this.gap/2),viewCenter-(this.height/2)], Math.sin(time*0.6));
      path.lineTo([viewCenter+(this.gap/2),viewCenter+(this.height/2)]);

      path.strokeColor = '#000';
      path.strokeWidth = 10;
      
      var center = (viewCenter-(this.height/2)) + (this.stick*(this.height*0.9));

      var line = new paper.Path([viewCenter-(this.gap/2), center]);
      line.lineTo([viewCenter+(this.gap/2),center]);

      line.strokeColor = '#000';
      line.strokeWidth = 10;

      for(let i = 0 ; i < this.shadow ; i++) {
        var sPath = path.clone();
        sPath.fillColor = null;
        sPath.strokeColor = '#000';
        sPath.strokeWidth = 2;
        sPath.position.y += i*5;
        sPath.rotate(i*3);
        var sLine = line.clone();
        sLine.position.y += i*5;
        sLine.strokeColor = '#000';
        sLine.strokeWidth = 2;
        // sLine.rotate(i*3);
      }
      
    };   
  }


  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

}