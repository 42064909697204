import '../styles/playground/musicBox.scss'
import Art from '../three-js-projects/musical-instrument/art.js';
import { useEffect } from 'react';
import Infobar from './Infobar'
import { designs } from '../util.js'
import ContentItem from '../ContentItem'

function MusicBox() {

  useEffect(() => {

    document.title = 'Music Box using THREE JS | Karan Mhatre';

    Art.init();

    window.addEventListener('click', () => {
      Art.addAudio();
    });
    
    return () => {
      Art.removeAllElements();
    };
  }, [])

  return (
    <div className="musical-container art-container">
      <div className="art">
        <canvas id="musicalInstrument" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / music box</h3>
        <p>Implementing an interactive music box on the web<br></br><br></br>
        Built using THREE.JS and CANNON.JS for the physics.<br></br><br></br>
        Click anywhere on the page to enable sound.
        </p>
        <div className="gui-controls"></div>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MusicBox;
