import '../styles/ImageSlider.scss';
import { NavLink } from "react-router-dom";

function ImageSlider() {
  return (
    <div className="image-slider container" id="image-slider"> 
      <ul>
        <li><div className="image"><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_600/v1622295504/Dribbble_shot_-_53_aphkvo.webp" alt=""/></div></li>
        <li><div className="image"><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_600/v1622295580/Dribbble_shot_-_52_ieuoxg.webp" alt=""/></div></li>
        <li>
          <div className="image"><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_600/v1622295575/Dribbble_shot_-_51_bxetdf.webp" alt=""/></div>
        </li>
      </ul>
      <NavLink to="/work/interface" className="work-link">View my designs →</NavLink>
    </div>
  );
}

export default ImageSlider;