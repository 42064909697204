import paper from 'paper'
import { setup } from 'paper/dist/paper-core';
import { Points } from 'three';

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getRandomColor() {
    return this.colors[this.getRandomInt(0,this.colors.length-1)];
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');
    this.canvas.style.height = this.canvas.clientWidth+"px";
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];

    this.xStep = this.canvas.clientWidth/10;
    this.yStep = this.canvas.clientHeight/10;

    paper.setup(this.canvas);
    this.setup();
    this.addBg();
    paper.view.draw();
  
  }

  addBg() {
    var rect = new paper.Path.Rectangle({
      point: [0, 0],
      size: [paper.view.size.width, paper.view.size.height],
    });
    rect.sendToBack();
    rect.fillColor = '#000';
  }

  setup() {
    this.paths = [];
    var counter = 0;
    for(let i = 0; i<10; i++) {
      for(let j = 0; j<10; j++) {
        this.paths[counter] = new paper.Path();
        this.paths[counter].strokeColor = this.getRandomColor();
        this.paths[counter].strokeWidth = this.getRandomInt(0.5,2);
        // this.paths[counter].opacity = 0.2;
        this.paths[counter].moveTo(((i)*this.xStep)+24, ((j)*this.yStep)+24);
        var x = this.paths[counter].firstSegment.point.x;
        var y = this.paths[counter].firstSegment.point.y;
        
        counter++;
      }
    }

    this.draw();

  }

  // createBranch(current) {
    
  //   return current;
  // }

  draw() {
    paper.view.onFrame = (event) => {
      var time = event.time;
      
      var counter = 0;

      for(let i = 0; i<10; i++) {
        for(let j = 0; j<10; j++) {
          var x = this.paths[counter].lastSegment.point.x;
          var y = this.paths[counter].lastSegment.point.y;
          if(this.paths[counter].lastSegment.point.getDistance(this.paths[counter].firstSegment.point) < 20) {
            this.paths[counter].add(i*0.02+x+Math.sin(time)*0.2, j*0.02+y+Math.cos(time)*0.2);
          }
          else {
            this.paths[counter].removeSegments();
            this.paths[counter] = new paper.Path();
            this.paths[counter].strokeColor = this.getRandomColor();
            this.paths[counter].strokeWidth = this.getRandomInt(0.5,2);
            this.paths[counter].moveTo(((i)*this.xStep)+24, ((j)*this.yStep)+24);
          }
          counter++;
        }
      }
    };   
  }

  downloadImage() {
    var image = this.canvas.toDataURL();
    var aDownloadLink = document.createElement('a');

    aDownloadLink.download = 'poster-karanmhatre-dot-com-'+Date.now()+'.png';

    aDownloadLink.href = image;

    aDownloadLink.click();
  }

  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

}