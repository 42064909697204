import '../styles/playground/mountain.scss'
import Art from '../three-js-projects/mountains/art.js';
import { useEffect } from 'react';
import {gsap} from 'gsap';

function Mountain() {

  useEffect(() => {

    document.title = 'Mountains | Karan Mhatre';

    Art.init();
    document.querySelector('body').style.opacity = 1;

    gsap.from(Art.rocket.position, {
      x: 50,
      duration: 1.3,
      ease: 'power3.inOut'
    });

    gsap.from(Art.rocket.rotation, {
      y: 3,
      duration: 1.3,
      ease: 'power3.inOut'
    });

    document.getElementById('next-btn').addEventListener('click', () => {

      document.getElementById('next-btn').classList.add('active');
      document.getElementById('prev-btn').classList.remove('active');

      gsap.to(Art.rocket.position, {
        x: -50,
        duration: 1.3,
        ease: 'power3.inOut'
      });
      
      gsap.to(Art.rocket.rotation, {
        y: -3,
        duration: 1.3,
        ease: 'power3.inOut'
      });

      gsap.to(Art.blanc.position, {
        x: 0,
        duration: 1.3,
        ease: 'power3.inOut'
      });
      
      gsap.to(Art.blanc.rotation, {
        y: -1.6,
        duration: 1.3,
        ease: 'power3.inOut'
      });
    });

    document.getElementById('prev-btn').addEventListener('click', () => {
      
      document.getElementById('next-btn').classList.remove('active');
      document.getElementById('prev-btn').classList.add('active');

      gsap.to(Art.rocket.position, {
        x: 0,
        duration: 1.3,
        ease: 'power3.inOut'
      });
      
      gsap.to(Art.rocket.rotation, {
        y: 0,
        duration: 1.3,
        ease: 'power3.inOut'
      });

      gsap.to(Art.blanc.position, {
        x: 50,
        duration: 1.3,
        ease: 'power3.inOut'
      });
      
      gsap.to(Art.blanc.rotation, {
        y: 1,
        duration: 1.3,
        ease: 'power3.inOut'
      });
    });

    return () => {
      Art.obj.renderer.dispose();
    };

  })

  return (
    <div className="moutain-container">
      <h1 id="text">MOUNTAINS</h1>
      <div className="container">
        <canvas className="webgl" id="mountain"></canvas>
      </div>
      <ul className="options">
        <li><span id="prev-btn" className="active">Kilimanjaro</span></li>
        <li><span id="next-btn">Mt.Blanc</span></li>
      </ul>
    </div>
  );
}

export default Mountain;
