import '../styles/Links.scss';
import { Link, NavLink } from "react-router-dom";

function Links() {
  return (
    <div className="links container">
      <div>
        <h3>case studies</h3>
        <ul>
          <li><Link to={`/design/case-studies/35`}>Eubrics 360 feedback SAAS Platform</Link></li>
          <li><NavLink to="/design/case-studies/33">A New Classroom Experience at WhiteHat JR</NavLink></li>
        </ul>
      </div>
    </div>
  );
}

export default Links;