import '../styles/playground/color-beats.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/color-beats/art.js';

function ColorBeats() {

  useEffect(() => {

    document.title = 'Color Beats | Karan Mhatre';

    Art.init();
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="color-beats-container art-container" id="color-beats-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / color beats</h3>
        <p>Given control over the scale, pitch, note sequencing, and order, the program has laid out controls in the form of shapes on the canvas<br></br><br></br>
        Click on a shape to activate the beat and click again to deactivate.<br></br><br></br>
        Unique beats are generated each time you refresh the page. <br></br><br></br>
        I'm not a musician, so suggestions are welcome on how to improve the constraints to create better sounding beats.
        </p>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ColorBeats;
