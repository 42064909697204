import paper from 'paper'
import Hindi from './Hindi.js'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];
    
    this.canvas.style.height = this.canvas.offsetWidth + "px";

    paper.setup(this.canvas);
    Hindi.generate();
    paper.view.draw();
    this.draw();
  }

  draw() {
    paper.view.onFrame = (event) => {
      var time = event.time;
      if(Math.floor(time)%2 == 0) {
        Hindi.generate();
      }
    };   
  }

  cleanCanvas() {
    paper.project.activeLayer.removeChildren();
  }

  downloadImage() {
    var image = this.canvas.toDataURL();
    var aDownloadLink = document.createElement('a');

    aDownloadLink.download = 'poster-karanmhatre-dot-com-'+Date.now()+'.png';

    aDownloadLink.href = image;

    aDownloadLink.click();
  }


}