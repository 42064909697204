import paper from 'paper'
import { setup } from 'paper/dist/paper-core';

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');

    this.radiusExp = 2;
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];

    
    this.offset = 80;

    paper.setup(this.canvas);
    paper.view.draw();
    
    this.step = paper.view.size.height/25;
    this.setup();

    this.StartTime = Date.now();

    this.tick();
  }

  setup() {

  }

  tick() {
    paper.view.onFrame = (event) => {

      this.time = (Date.now()-this.StartTime)*0.001;

      var time = this.time;

      if(event.count%1 == 0) {
        
        paper.project.activeLayer.removeChildren();

        this.scales();

        var timeText = new paper.PointText([580, 40]);
        timeText.fillColor = '#FB9300';
        timeText.content = "time=" + this.time.toFixed(2);
        timeText.fontWeight = 400;
        timeText.fontSize = 20;

        for(let x=1; x<=20;x++) {
          for(let y=1; y<=20;y++) {
            var rad = eval(this.radiusExp);
            var myCircle = new paper.Path.Circle([this.offset+(x*this.step),this.offset+(y*this.step)], rad);
            myCircle.fillColor = '#000';
            this.counter++;
          }
        }
      }

    };   
  }

  scales() {

    var scaleColor = 'blue';

    for(let i=0;i<=20;i++) {
      var text = new paper.PointText([this.offset+(i*this.step), this.offset]);
      text.fillColor = scaleColor;
      if(i==0) {
        text.content = "x";
        text.fillColor = '#FB9300';
        // text.fontFamily = 'Times New Roman';
        text.fontWeight = 900;
        text.fontSize = 16;
      }
      else {
        text.content = i;
        text.fontSize = 10;
      }

      text.position.x = this.offset+(i*this.step);
    }

    for(let i=0;i<=20;i++) {
      var text = new paper.PointText([this.offset+(i*this.step), this.offset-20]);
      text.fillColor = scaleColor;
      
      if(i==0) {
        text.content = "Math.sin(x)";
        text.fillColor = '#FB9300';
        // text.fontFamily = 'Times New Roman';
        text.fontWeight = 900;
        text.fontSize = 16;
        text.position.x = this.offset+(i*this.step)-30;
      }
      else {
        text.content = Math.sin(i).toFixed(1);
        text.fontSize = 10;
        text.position.x = this.offset+(i*this.step);
      }
    }

    for(let i=0;i<=20;i++) {
      var text = new paper.PointText([paper.view.size.width-this.offset-40, this.offset+(i*this.step)]);
      text.fillColor = scaleColor;
      if(i==0) {
        text.fillColor = '#FB9300';
        text.content = "y";
        // text.fontFamily = 'Times New Roman';
        text.fontWeight = 900;
        text.fontSize = 16;
      }
      else {
        text.content = i;
        text.fontSize = 10;
      }
      text.position.y = this.offset+(i*this.step);
    }

    for(let i=0;i<=20;i++) {
      var text = new paper.PointText([paper.view.size.width-this.offset-10, this.offset+(i*this.step)]);
      text.fillColor = scaleColor;
      if(i==0) {
        text.fillColor = '#FB9300';
        text.content = "Math.sin(y)";
        // text.fontFamily = 'Times New Roman';
        text.fontWeight = 900;
        text.fontSize = 16;
      }
      else {
        text.content = Math.sin(i).toFixed(1);
        text.fontSize = 10;
      }
      text.position.y = this.offset+(i*this.step);
    }
    
  }


  cleanCanvas() {
    paper.project.activeLayer.removeChildren();
  }

}