import '../styles/playground/strings.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/strings/art.js';

function Strings() {

  useEffect(() => {

    document.title = 'Strings | Karan Mhatre';

    Art.init();
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="strings-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / strings</h3>
        <p>Play some tunes on this custom guitar circle.<br></br><br></br>
        Each click with detect the closest string and play it. Each string is mapped to a guitar note. The string will animate near the point of contact.
        </p>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Strings;
