import * as THREE from 'three'
import gsap from 'gsap'

export default new class {

  init() {

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }
    
    /* SCENE STARTER */
    
    const canvas = document.querySelector('#spaceTravel');
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height);
    const clock = new THREE.Clock();
    // const controls = new OrbitControls(camera, canvas);
    // const gui = new dat.GUI();
    
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      // setClearColor: 0xd2d2d2
      alpha: true
    });
    
    // renderer.setClearColor(0xd2d2d2, 1);
    
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFShadowMap;
    
    window.addEventListener('resize', () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
    });
    
    /* END SCENE STARTER */
    
    /* SCENE ELEMENTS */
    
    const material = new THREE.PointsMaterial();
    
    material.size = 0.02;
    material.sizeAttenuation = true;
    
    const sphere = new THREE.Points(
      new THREE.SphereBufferGeometry(2.3,32,32),
      material
    );
    
    sphere.rotation.x = .8;
    // sphere.rotation.z = .5;
    
    scene.add(sphere);
    
    /* END SCENE ELEMENTS */
    
    /* CAMERA POSITIONING */
    
    camera.position.set(1, 1, -1.4);
    // camera.position.set(0, 3, 4);
    // camera.position.set(1, 1, -1.4);
    
    /* END CAMERA POSITIONING */
    
    let tl = gsap.timeline({onComplete:function() {
      this.restart(); }
    });
    
    tl.to(camera.position, {
      x: 0,
      y: 0,
      z: 8,
      delay: 5,
      duration: 5,
      ease: 'power4.inOut'
    });
    

    tl.to(camera.position, {
      x: 0,
      y: 1,
      z: 2,
      duration: 5,
      ease: 'power4.inOut'
    });

    tl.to(camera.position, {
      x: 0,
      y: 3,
      z: 3,
      duration: 5,
      ease: 'power4.inOut'
    });

    tl.to(camera.position, {
      x: 0,
      y: 1,
      z: 2,
      duration: 5,
      ease: 'power4.inOut'
    });

    tl.to(camera.position, {
      x: 1,
      y: 1,
      z: -1.4,
      duration: 5,
      ease: 'power4.inOut'
    });
    
    const tick = () =>
    {

      setTimeout(() => {
        window.requestAnimationFrame(tick);  
        const elapsedTime = clock.getElapsedTime();
        sphere.rotation.y = elapsedTime*.7;
        // camera.lookAt(sphere.position);
        renderer.render(scene, camera);

      }, 1000 / 120 );

    }
    
    tick();
  }
}