import '../styles/playground/rocks.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/generative-rocks/art.js';

function GenerativeRocks() {

  useEffect(() => {

    document.title = 'Generative Rocks | Karan Mhatre';

    Art.init();  

    var animateBtn = document.getElementById('animateBtn');

    animateBtn.addEventListener('click', () => {
      Art.rotate = !Art.rotate;
      if(Art.rotate)
        animateBtn.innerHTML = 'Stop';
      else
        animateBtn.innerHTML = 'Animate';
    });
  
    document.getElementById('genBtn').addEventListener('click', () => {
      for(let i=0; i<Art.rocks.length;i++)
        Art.changeRock(i);
    });
    
    return () => {
      Art.removeAllElements();
    };

  })

  return (
    <div className="rocks-container art-container">
      <div className="art">
        <canvas id="rocksWebgl" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / generative rocks</h3>
        <p>Each polygon deserves the chance to be unique.<br></br><br></br>
        Generate vertices in the form of a cube, and randomlly pull some vertices outward so create low-poly rock formations.<br></br><br></br>
        Each piece is unique and new.
        </p>
        <ul className="options">
          <li><button id="genBtn">Generate</button></li>
          <li><button id="animateBtn">Stop</button></li>        
        </ul>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default GenerativeRocks;
