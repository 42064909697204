import Scene from './scene-starter.js'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

export default new class {

  init() {
    this.obj = Scene;
    
    this.canvas = document.getElementById('mountain');

    this.obj.init(this.canvas);

    this.addLights();
    this.clock = new THREE.Clock();
    
    this.obj.addEvents();
    
    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath('/draco/');

    this.gltfLoader = new GLTFLoader();
    this.gltfLoader.setDRACOLoader(this.dracoLoader);

    this.rocket = new THREE.Group();
    
    this.gltfLoader.load('https://res.cloudinary.com/dgksx9vlc/image/upload/v1623594274/Website/mountain_lsq3qm.glb', (gltf) => {
      gltf.scene.receiveShadow = true;
      gltf.scene.castShadow = true;
      this.rocket.add(gltf.scene);
    });

    this.blanc = new THREE.Group();
    
    this.gltfLoader.load('https://res.cloudinary.com/dgksx9vlc/image/upload/v1623594274/Website/blanc_ds2hbd.glb', (gltf) => {
      gltf.scene.receiveShadow = true;
      gltf.scene.castShadow = true;
      this.blanc.add(gltf.scene);
    });

    this.obj.camera.lookAt(new THREE.Vector3(0,0,0));

    // this.rocket.rotation.x = .6;
    this.rocket.scale.set(1.1,1.1,1.1);
    this.rocket.position.set(0,-5,0);
    this.rocket.castShadow = true;

    this.obj.scene.add(this.rocket);
    
    this.blanc.position.set(50,-8,-2);
    this.blanc.scale.set(1.8,1.8,1.8);
    this.blanc.rotation.z = 0.2;
    this.blanc.rotation.y = 1;
    // this.blanc.rotation.y = 2;

    this.obj.scene.add(this.blanc);

    this.startTick();
  }

  addLights() {
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
    this.obj.scene.add(this.ambientLight);

    this.pointLight = new THREE.PointLight(0xffffff,1.6, 80);
    this.pointLight.castShadow = true;

    // this.obj.gui.add(this.pointLight.position, 'x', -30, 30, 2);
    // this.obj.gui.add(this.pointLight.position, 'y', -30, 30, 2);
    // this.obj.gui.add(this.pointLight.position, 'z', -30, 30, 2);
    this.pointLight.position.set(-22,6,14);
    this.obj.scene.add(this.pointLight);

    this.pointLight2 = new THREE.PointLight(0xff0000,1, 40);
    this.pointLight2.castShadow = true;
    this.pointLight2.position.set(10,20,15);
    this.obj.scene.add(this.pointLight2);
  }

  startTick() {

    const tick = () => {

      setTimeout(() => {

        this.obj.render();
        window.requestAnimationFrame(tick);

      }, 1000 / 120 );

    }
    tick();
  }

  addGround() {
    this.ground = new THREE.Mesh(
      new THREE.BoxBufferGeometry(5,.2,5),
      new THREE.MeshStandardMaterial({ color: 0xffffff })
    );
    this.ground.receiveShadow = true;
    this.obj.scene.add(this.ground);
  }

}