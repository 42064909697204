import '../styles/playground/hindi-character.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/hindi-character/art.js';

function HindiCharacter() {

  useEffect(() => {

    document.title = 'Hindi Character Generation | Karan Mhatre';

    Art.init();


    document.getElementById('downloadBtn').addEventListener('click', () => {
      Art.downloadImage();
    });
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="hindi-character-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / hindi characters</h3>
        <p>Giving basic hindi letter characteristics to the program.<br></br><br></br>
        Unique characters are generated that vaguely resemble hindi characters.
        </p>
        <ul className="options">
          <li><button id="downloadBtn">Download</button></li>
        </ul>

        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HindiCharacter;
