import Scene from './scene-starter.js'
import * as THREE from 'three'

export default new class {

  init() {
    this.obj = Scene;

    this.canvas = document.getElementById('antimatter');

    this.params = {
      xColor: 2.3,
      yColor: 1.4,
      zColor: 0.9
    }

    this.obj.init(this.canvas);
    
    this.addGround();
    this.clock = new THREE.Clock();
    this.startTick();
  }

  startTick() {
    const tick = () => {

      setTimeout(() => {

        const elapsedTime = this.clock.getElapsedTime();
        this.material.uniforms.uTime.value = elapsedTime;
        this.obj.render();
        window.requestAnimationFrame(tick);
      }, 1000 / 30 );
      
    }
    
    tick();
  }

  addGround() {

    this.geometry = new THREE.IcosahedronBufferGeometry(1, 64);

    this.material = new THREE.ShaderMaterial({ 
      vertexShader: 
      `
      varying float vDistortion;
      varying float vxColor;
      varying float vyColor;
      varying float vzColor;
      
      uniform float uTime;
      
      uniform float uxColor;
      uniform float uyColor;
      uniform float uzColor;
      
      uniform float uNoiseDensity;
      uniform float uNoiseIntensity;
      
      varying vec2 vUv;
        
      vec3 mod289(vec3 x)
      {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
      }
      
      vec4 mod289(vec4 x)
      {
        return x - floor(x * (1.0 / 289.0)) * 289.0;
      }
      
      vec4 permute(vec4 x)
      {
        return mod289(((x*34.0)+1.0)*x);
      }
      
      vec4 taylorInvSqrt(vec4 r)
      {
        return 1.79284291400159 - 0.85373472095314 * r;
      }
      
      vec3 fade(vec3 t) {
        return t*t*t*(t*(t*6.0-15.0)+10.0);
      }
      
      float pnoise(vec3 P, vec3 rep)
      {
        vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
        vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
        Pi0 = mod289(Pi0);
        Pi1 = mod289(Pi1);
        vec3 Pf0 = fract(P); // Fractional part for interpolation
        vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
        vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
        vec4 iy = vec4(Pi0.yy, Pi1.yy);
        vec4 iz0 = Pi0.zzzz;
        vec4 iz1 = Pi1.zzzz;
      
        vec4 ixy = permute(permute(ix) + iy);
        vec4 ixy0 = permute(ixy + iz0);
        vec4 ixy1 = permute(ixy + iz1);
      
        vec4 gx0 = ixy0 * (1.0 / 7.0);
        vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
        gx0 = fract(gx0);
        vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
        vec4 sz0 = step(gz0, vec4(0.0));
        gx0 -= sz0 * (step(0.0, gx0) - 0.5);
        gy0 -= sz0 * (step(0.0, gy0) - 0.5);
      
        vec4 gx1 = ixy1 * (1.0 / 7.0);
        vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
        gx1 = fract(gx1);
        vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
        vec4 sz1 = step(gz1, vec4(0.0));
        gx1 -= sz1 * (step(0.0, gx1) - 0.5);
        gy1 -= sz1 * (step(0.0, gy1) - 0.5);
      
        vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
        vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
        vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
        vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
        vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
        vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
        vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
        vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
      
        vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
        g000 *= norm0.x;
        g010 *= norm0.y;
        g100 *= norm0.z;
        g110 *= norm0.w;
        vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
        g001 *= norm1.x;
        g011 *= norm1.y;
        g101 *= norm1.z;
        g111 *= norm1.w;
      
        float n000 = dot(g000, Pf0);
        float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
        float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
        float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
        float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
        float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
        float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
        float n111 = dot(g111, Pf1);
      
        vec3 fade_xyz = fade(Pf0);
        vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
        vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
        float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
        return 2.2 * n_xyz;
      }
      
      void main() {
        float t = uTime * 0.1;
        
        float distortion = pnoise((normal + t) * uNoiseDensity, vec3(10.0)) * uNoiseIntensity;
      
        // Disturb each vertex along the direction of its normal
        vec3 pos = position + (normal * distortion);
      
        vUv = uv;
      
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        
        vDistortion = distortion;
        vxColor = uxColor;
        vyColor = uyColor;
        vzColor = uzColor;
      }`,
      fragmentShader: `
      precision mediump float;
varying float vDistortion;
varying vec2 vUv;

varying float vxColor;
varying float vyColor;
varying float vzColor;

void main() {
  // vec4 color = vec4(vUv.x+0.4,0.8,vUv.y,1.0);
  vec4 color = vec4(
    vUv.x * vxColor,
    vUv.y * vyColor,
    vUv.y * vzColor,1.0
    );
  color.rbg -= vDistortion*0.6;
  gl_FragColor = color;
}`,
      side: THREE.DoubleSide,
      uniforms: 
      {
        uTime: { value: 0 },
        uNoiseDensity: { value: 4.0 },
        uxColor: { value: this.params.xColor },
        uyColor: { value: this.params.yColor },
        uzColor: { value: this.params.zColor },
        uNoiseIntensity: { value: 0.2 }
      }
      // wireframe: true
    });

    this.ground = new THREE.Mesh(
      this.geometry,
      this.material
    );

    this.ground.position.set(0,0,0);
    this.ground.rotation.y = 1;

    this.obj.scene.add(this.ground);

    // window.addEventListener('mousemove', (event) => {
    //   this.material.uniforms.uNoiseDensity.value = ((event.screenX/this.obj.sizes.width)*0.2*2) + 2;
    //   this.material.uniforms.uNoiseIntensity.value = ((event.screenY/this.obj.sizes.height)*0.05) + 0.5;
    // });
  }

  removeAllElements() {
    this.obj.scene.clear();

    
    for( var i = this.obj.scene.children.length - 1; i >= 0; i--) { 
      var obj = this.obj.scene.children[i];
      
      if(obj.geometry !== undefined) {
        obj.geometry.dispose();
        obj.material.dispose();
      }
      else {
        console.log(obj);
        obj.dispose();
      }
        
    }
    
    this.obj.renderer.dispose();
  }

}