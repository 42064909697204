import './App.scss';
import './styles/ProjectDetails.scss';
import { motion } from 'framer-motion';

// Utils
import { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { designs } from './util.js'

import ContentItem from './ContentItem'
import Header from './Header'


function ProjectDetails(props) {
  let { id } = useParams();
  let history = useHistory();
  
  const image = designs.filter(design => design.id === id)[0];
  const content = image.sources;

  useEffect(() => {

    window.scrollTo(0, 0);

  }, []);

  return (
    <div className="design work-details-container">
      <div className="fixed-back-btn-container mobile-only">
        <span className="mobile-only" onClick={() => history.goBack()}>← Home</span>
      </div>
      <div className="container desktop-only">
        <span className="close-btn" onClick={() => history.goBack()}></span>
      </div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.8 }}
        >
        {/* <span id="prev-btn">Prev</span>
        <span id="next-btn">Next</span> */}
        <div className="image-container">
          {image.type === "img" && 
            <img src={ "https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_2400"+image.thumbnail } alt=""/>  
          }
          {image.type === "vid" && 
            <video autoPlay muted loop>
              <source src={image.thumbnail} type="video/mp4" />
            </video>
          }
        </div>
        <div className="container design-desc">
          <h2>{ image.name }</h2>
          <p>{ image.text }</p>
        </div>
        <div className="content container">
            { content.map(item => {
              return ContentItem(item);
            })}
        </div>
      </motion.div>
    </div>
  );
}

export default ProjectDetails;