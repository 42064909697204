import './App.scss';
import './styles/Work.scss';

// Components
import Header from './Header'
import Footer from './home/Footer'
import DesignItem from './DesignItem'
import { gsap } from 'gsap';

import {BrowserRouter as Switch, Route} from 'react-router-dom';

// Utils
import { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { motion } from 'framer-motion';
import { designs } from './util.js'

function Work() {

  const interfaces = designs.filter(design => design.category === "interfaces");
  const motionDesigns = designs.filter(design => design.category === "motion-design"); 
  const digitalIllustrations = designs.filter(design => design.category === "digital-illustrations"); 
  const paintings = designs.filter(design => design.category === "paintings"); 
  const threeDImages = designs.filter(design => design.category === "3d"); 

  useEffect(() => {
    console.log("work loaded");
    window.scrollTo(0, 0);
    
    // gsap.from('.loading-screen', {
    //   height: '100vh',
    //   duration: 1,
    //   delay: 1,
    //   ease: 'power3.inOut'
    // });
    
    // window.scroll({
    //   top: 0,
    //   behavior: 'auto'
    // });

  }, []);

  return (
    <div className="work-container">
      <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
        <Header/>
        <div className="container">
          <ul className="options">
            <li><NavLink activeClassName="active" to="/work/interface">interfaces ({interfaces.length})</NavLink></li>
            <li><NavLink activeClassName="active" to="/work/motion-design">motion ({motionDesigns.length})</NavLink></li>
            <li><NavLink activeClassName="active" to="/work/painting">painting ({paintings.length})</NavLink></li>
            <li><NavLink activeClassName="active" to="/work/digital-illustrations">digital art ({digitalIllustrations.length})</NavLink></li>
            <li><NavLink activeClassName="active" to="/work/3d">3D art ({threeDImages.length})</NavLink></li>
          </ul> 
        </div>
        <div className="designs">
          <Route path='/work/motion-design'>
            { motionDesigns.map(item => {
                return DesignItem(item);
            })}
          </Route>
          <Route path='/work/interface'>
            {interfaces.map(item => {
                return DesignItem(item);
            })}
          </Route>
          <Route path='/work/3d'>
            {threeDImages.map(item => {
                return DesignItem(item);
            })}
          </Route>
          <Route path='/work/painting'>
            {paintings.map(item => {
                return DesignItem(item);
            })}
          </Route>
          <Route path='/work/digital-illustrations'>
            {digitalIllustrations.map(item => {
                return DesignItem(item);
            })}
          </Route>
        </div>
        <Footer />
      </motion.div>
    </div>
  );
}

export default Work;