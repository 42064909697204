import '../styles/playground/letItRain.scss'
import Art from '../three-js-projects/let-it-rain/art.js';
import { useEffect } from 'react';

function LetItRain() {

  useEffect(() => {

    let interv;

    // window.scroll({ top: 0 });
    document.querySelector('body').style.display = 'block';
    Art.init();

    document.getElementById('letItRainBtn').addEventListener('mousedown', function() {

      interv = setInterval(function(){
    
        document.querySelector('.let-it-rain-container').style.backgroundColor = '#fff';
    
        Art.addBall(
          Math.random()*0.2,
          (Math.random()-0.5)*5,
          8,
          (Math.random()-0.5)*5
        );
      }, 100);
    });
    
    document.getElementById('letItRainBtn').addEventListener('mouseup', function() {

      console.log("mouseUp");
      document.querySelector('.let-it-rain-container').style.backgroundColor = '#000';
      clearInterval(interv);
    });

    return () => {
      Art.obj.renderer.dispose();
    };

  })

  return (
    <div className="let-it-rain-container">
      <h1>PHYSICS</h1>
      <span id="letItRainBtn">LET IT RAIN</span>
      <p>Drag to move the camera</p>
      <canvas id="letItRain"></canvas>
    </div>
  );
}

export default LetItRain;
