import '../styles/Hero.scss';
import { ReactComponent as LimeCircle } from '../pink-line.svg';
import { useEffect } from 'react';
import paper from 'paper';
import { motion } from 'framer-motion';

function Hero() {

  useEffect(() => {

    // var canvas = document.getElementById('imageContainer');

    // var rect = canvas.getBoundingClientRect();

    // var paths = [];

    // var radius = 4;

    // canvas.addEventListener('mouseover', (event) => {

    //   cloneRaster.size = new paper.Size(55,50);
    //   raster.visible = false;

    //   for (var y = 0; y < 50; y+=1) {
    //     for(var x = 0; x < 55; x+=1) {
    //       var color = cloneRaster.getPixel(x, y);
          
    //       var path = new paper.Path.Circle({
    //         center: new paper.Point(x*10, y*10),
    //         radius: 4
    //       });
  
    //       path.fillColor = color;

    //       paths.push(path);
    //     }
    //   }
    // });

    // canvas.addEventListener('mouseleave', () => {
    //   raster.visible = true;
      
    //   for(let i = 0 ; i< paths.length ; i++) {
    //     paths[i].remove();
    //   }
    // });
    
    // paper.setup(canvas);
      
    // var url = 'https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_800/v1623053232/Dribbble_shot_-_55_inigde.png';
    
    // var image = new Image(100,100);
    // image.src = url;
    // image.crossOrigin = "Anonymous";

    // var raster = new paper.Raster(image);
    // raster.size = new paper.Size(550,500);
    // raster.position = paper.view.center;
    // raster.visible = true;
    // var cloneRaster = raster.clone();
    // cloneRaster.visible = false;
    
    
    // paper.view.draw();

  });

  return (
    <div className="hero container">
      <div className="info" data-scroll-section>
        <LimeCircle className="line" />
        <h2 className="desktop-only"><span>Product Designer, Illustrator,</span></h2>
        <h2 className="desktop-only"><span>Creative Developer.</span></h2>
        <h2 className="mobile-only"><span>Product Designer,</span></h2>
        <h2 className="mobile-only"><span>Illustrator, Creative</span></h2>
        <h2 className="mobile-only"><span>Developer.</span></h2>
        <p className="mobile-only">I help companies build smart design systems, with an emphasis on Visuals and Interactions. I also paint, and create 3D Interactive Art.</p>
        <p className="desktop-only">I help companies build smart design systems, with an<br />emphasis on Visuals and Interactions. I also paint, and <br />create 3D Interactive Art.</p>
      </div>
      <div className="image-container">
        <canvas id="imageContainer"></canvas>
        <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_800/v1623053232/Dribbble_shot_-_55_inigde.webp" alt=""/>
        <motion.div
          exit={{ opacity: 0 }}
          transition={{ duration: 0 }}
        ><canvas id="antimatter"></canvas>
        </motion.div>
      </div>
      <motion.div>
        <img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1622292727/lime-star_lvacxy.svg" alt="" className="lime-star" />
      </motion.div>
    </div>
  );
}

export default Hero;