import Scene from './scene-starter.js'
import * as THREE from 'three'
import gsap from 'gsap'

export default new class {
  
  init() {
    this.obj = Scene;

    this.canvas = document.getElementById('shyCircles');

    this.obj.init(this.canvas);

    this.extrudeSettings = {
      steps: 10,
      depth: 0.1,
      bevelEnabled: true,
      bevelThickness: 0.01,
      bevelSize: 0.05,
      bevelOffset: 0.1,
      bevelSegments: 10
    };

    this.circlesArr = [];

    this.addAllCircles();
    this.addCircle();
    this.addLights();
    this.addHoverEvent();
    this.startTick();
  }

  addHoverEvent() {
    window.addEventListener('mousemove', (event) => {
      const left = (this.obj.sizes.width - event.clientX)/this.obj.sizes.width;

      for(let i=1; i < 30; i++) {
        let scale = 30*Math.abs((left-0.5)*(i/40))+1;

        gsap.to(this.circlesArr[i].scale, {
          x: scale,
          duration: 0.6,
          delay: 0.005
        });

        gsap.to(this.circlesArr[i].scale, {
          y: scale,
          duration: 0.6,
          delay: 0.005
        });

      }
    });

    window.addEventListener('click', (event) => {
      const left = (this.obj.sizes.width - event.clientX)/this.obj.sizes.width;

      for(let i=1; i < 30; i++) {
        let scale = 30*Math.abs((left-0.5)*(i/40))+1;

        gsap.to(this.circlesArr[i].scale, {
          x: scale,
          duration: 0.6,
          delay: 0.005
        });

        gsap.to(this.circlesArr[i].scale, {
          y: scale,
          duration: 0.6,
          delay: 0.005
        });

      }
    });
  }

  startTick() {
    const tick = () => {

      setTimeout(() => {

        window.requestAnimationFrame(tick);
        this.obj.render();

      }, 1000 / 120 );

    }
    
    tick();
  }  


  addAllCircles() {
    for(let i=1; i < 30; i++) {
      this.addCircle(i)
    }
  }

  addCircle(i) {

    const circleRadius = i*1.2;
    const circleShape = new THREE.Shape();
    
    circleShape.moveTo( 0, circleRadius );
    circleShape.quadraticCurveTo( circleRadius, circleRadius, circleRadius, 0 );
    circleShape.quadraticCurveTo( circleRadius, -circleRadius, 0, -circleRadius );
    circleShape.quadraticCurveTo( -circleRadius, -circleRadius, -circleRadius, 0 );
    circleShape.quadraticCurveTo( -circleRadius, circleRadius, 0, circleRadius );

    const geometry = new THREE.ExtrudeGeometry(circleShape, this.extrudeSettings );

    this.circle = new THREE.Mesh(
      geometry, 
      new THREE.MeshStandardMaterial({ color: 0xffffff,
        roughness: 1,
        metalness: 0.4
       })
    );

    this.circle.position.z = -i*0.5;
    this.obj.scene.add(this.circle);

    this.circle.castShadow=true;
    // this.circle.receiveShadow=true;


    this.circlesArr[i] = this.circle;
  }

  addLights() {
    // this.light = new THREE.AmbientLight(0xf05053, 1.5);
    // this.pointLight = new THREE.PointLight(0xe1eec3,2);
    this.light = new THREE.AmbientLight(0xffffff, 1.5);
    this.pointLight = new THREE.PointLight(0xffffff,1);
    this.pointLight.position.set(-25,25,1);
    this.obj.scene.add(this.light);
    this.pointLight.castShadow=true;

    // this.obj.gui.add(this.pointLight.position, 'x', -5, 5, 1);
    // this.obj.gui.add(this.pointLight.position, 'y', -5, 5, 1);
    // this.obj.gui.add(this.pointLight.position, 'z', -5, 5, 1);
    
    this.obj.scene.add(this.pointLight);
  }

  removeAllElements() {
    this.obj.scene.clear();
    
    for( var i = this.obj.scene.children.length - 1; i >= 0; i--) { 
      var obj = this.obj.scene.children[i];
      
      if(obj.geometry !== undefined) {

        obj.geometry.dispose();
        obj.material.dispose();
      }
      else {

        obj.dispose();
      }
        
    }

    // window.removeEventListener('mousemove', moveCircles, false);
    
    this.obj.renderer.dispose();
  }

}