import Scene from './scene-starter.js'
import * as THREE from 'three'
import CANNON from 'cannon'

export default new class {

  init() {
    this.obj = Scene;
    this.clock = new THREE.Clock();

    this.canvas = document.getElementById('musicalInstrument');
    this.obj.init(this.canvas);
    
    this.oldTime = this.clock.getElapsedTime();
    
    this.world = new CANNON.World();
    this.world.gravity.set(0,-20,0);

    this.audioArr = [
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943767/0_vqvn2i.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943767/1_qioctv.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943767/2_hak4bz.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943767/3_gqfrk7.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943767/4_rsya6k.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943768/5_rdae1m.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943768/6_yhqm1v.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943768/7_iiel9l.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943768/8_npejtn.mp3'),
      new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621943768/9_jdgwlj.mp3'),
    ];
    
    const defaultMaterial = new CANNON.Material('default');
    const defaultContactMaterial = new CANNON.ContactMaterial(
      defaultMaterial,
      defaultMaterial,
      {
        friction: 10.0,
        restitution: 0.0
      }
    );

    this.world.addContactMaterial(defaultContactMaterial);
    this.world.defaultContactMaterial = defaultContactMaterial;

    this.bellsArray = new Array(10);
    this.platesArray = new Array(10);

    this.obj.scene.position.set(1.1, 1, 1.2)
    this.obj.scene.rotation.y = -0.5;

    this.params = {};
    this.params.speed = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 2,
      9: 3,
    };

    this.params.startRotation = true;

    // this.obj.gui.open();

    for (let i = 0; i < 10; i++) {
      this.addBell(i);
      this.addPlate(i);
      this.obj.gui.add(this.params.speed, i, 0, 10, 1);
    }

    this.addHinge();
    this.addLights();
    this.addStick();
    
    // var obj = { start: () => { this.startTick() }};
    
    this.obj.gui.add(this.params,'startRotation');
    
    this.startTick();
    // this.addAudio();
  }

  updateBodies() {

    for (let i = 0; i < 10; i++) {
      this.bellsArray[i][0].position.copy(this.bellsArray[i][1].position);
      this.bellsArray[i][0].quaternion.copy(this.bellsArray[i][1].quaternion);
    }

    for (let i = 0; i < 10; i++) {
      this.platesArray[i][0].position.copy(this.platesArray[i][1].position);
      this.platesArray[i][0].quaternion.copy(this.platesArray[i][1].quaternion);
    }
  }

  addHinge() {
    for (let i = 0; i < 10; i++) {
      const c1 = new CANNON.HingeConstraint(this.bellsArray[i][2], this.bellsArray[i][1], { 
        axisA: new CANNON.Vec3(0, 0, 0),
        axisB: new CANNON.Vec3(0, 0, 0),
        pivotA: new CANNON.Vec3(0, 0, 0),
        pivotB: new CANNON.Vec3(-1.2, 0, 0),
        maxForce: 100
      });
  
      this.world.addConstraint(c1);
    }
  }

  startTick() {

    const tick = () => {

      setTimeout(() => {

        let newTime = this.clock.getElapsedTime();
        let deltaTime = newTime - this.oldTime;
        this.oldTime = newTime;
        this.world.step(1/60, deltaTime, 3);

        if(this.params.startRotation) {
          for (let i = 0; i < 10; i++) {
            this.platesArray[i][0].rotation.z = newTime * this.params.speed[i];
            this.platesArray[i][1].quaternion.copy(this.platesArray[i][0].quaternion);
          }
        }
        else {
          for (let i = 0; i < 10; i++) {
            this.platesArray[i][0].rotation.z = 0;
            this.platesArray[i][1].quaternion.copy(this.platesArray[i][0].quaternion);
          }
        }

        // this.obj.scene.rotation.y = newTime * 0.4;
        // this.obj.controls.update();
        this.obj.render();
        this.updateBodies();
        window.requestAnimationFrame(tick);

      }, 1000 / 30 );
    }

    tick();
  }

  addAudio() {

    console.log("inside addAudio");

    for (let i = 0; i < 10; i++) {

      const playCollideAudio = (collision) => {

        let audio = this.audioArr[i];
        let velocity = collision.contact.getImpactVelocityAlongNormal();
        
        audio.volume = Math.random();
        
        if(velocity > 0.5 || velocity < 0.5) {
          audio.currentTime = 0;
          audio.play();
        }
      }

      this.bellsArray[i][1].addEventListener('collide', playCollideAudio);
    }
  }

  addStick() {
    this.stick = new THREE.Mesh(
      new THREE.CylinderBufferGeometry(0.1,0.1,10.2,30,30),
      new THREE.MeshStandardMaterial({ color: 0xffffff })
    )

    this.stick.rotation.set(Math.PI/2,0, 0);

    this.stick.position.set(-1, -2.5, -0.5);

    this.obj.scene.add(this.stick);
  }

  addBell(z) {
    this.slab = new THREE.Mesh(
      new THREE.BoxBufferGeometry(2,0.2,0.2),
      new THREE.MeshStandardMaterial({ color: new THREE.Color(1, 1, 1) })
    );
    
    this.slab.receiveShadow = true;
    this.slab.castShadow = true;

    this.slab.position.set(0,0,z-5);

    this.slabBody = new CANNON.Body({
      shape: new CANNON.Box(new CANNON.Vec3(1,0.1,0.1)),
      mass: 1,
      position: new CANNON.Vec3(0,0,z-5)
    });

    this.world.addBody(this.slabBody);

    this.obj.scene.add(this.slab);

    this.holder = new THREE.Mesh(
      new THREE.SphereBufferGeometry(0.1,30,30),
      new THREE.MeshStandardMaterial({ color: 0xffffff })
    );

    this.holder.receiveShadow = true;
    this.holder.position.set(-1.2,0,z-5);
    this.obj.scene.add(this.holder);

    this.holderBody = new CANNON.Body({
      shape: new CANNON.Box(new CANNON.Vec3(0.1,0.1,0.1)),
      mass: 0,
      position: new CANNON.Vec3(-1.2,0,z-5)
    });

    this.world.addBody(this.holderBody);

    this.bellsArray[z] = [this.slab, this.slabBody, this.holderBody];
  }

  addPlate(z) {
    this.slab2 = new THREE.Mesh(
      new THREE.BoxBufferGeometry(2,0.2,1),
      new THREE.MeshStandardMaterial({ color: new THREE.Color(1,1,1) })
    );

    this.slab2.castShadow = true;
    this.slab2.receiveShadow = true;

    this.slab2.position.set(-1,-2.5,z-5);

    this.slab2.rotation.z = Math.PI/2;

    this.obj.scene.add(this.slab2);
    
    this.slab2Body = new CANNON.Body({
      shape: new CANNON.Box(new CANNON.Vec3(1,0.1,0.5)),
      mass: 0,
      position: new CANNON.Vec3(-1,-2.5,z-5)
    });

    this.slab2Body.quaternion.copy(this.slab2.quaternion);
    this.world.addBody(this.slab2Body);
    
    this.platesArray[z] = [this.slab2, this.slab2Body];
  }

  addLights() {
    this.ambLight = new THREE.AmbientLight(0xffffff, 0.2);
    this.obj.scene.add(this.ambLight);

    this.light = new THREE.SpotLight(0xffffff, 1, 100);
    this.light.castShadow = true;
    this.light.position.set(10,0,15);
    this.obj.scene.add(this.light); 

    this.light2 = new THREE.PointLight(0x7303c0, 2, 100);
    this.light2.position.set(10,0,0);
    this.obj.scene.add(this.light2); 

    this.light3 = new THREE.PointLight(0xfdeff9, 1, 30);
    this.light3.position.set(-10,0,0);
    this.obj.scene.add(this.light3); 
  }

  removeAllElements() {
    this.obj.scene.clear();

    for (let i = 0; i < 10; i++) {
      this.world.removeBody(this.bellsArray[i][1]);
      this.world.removeBody(this.platesArray[i][1]);
    }
    
    for( var i = this.obj.scene.children.length - 1; i >= 0; i--) { 
      var obj = this.obj.scene.children[i];
      
      if(obj.geometry !== undefined) {
        console.log("if");
        console.log(obj);
        obj.geometry.dispose();
        obj.material.dispose();
      }
      else {
        console.log("else");
        console.log(obj);
        obj.dispose();
      }
        
    }
    
    this.obj.renderer.dispose();
  }

}

