import './App.scss';
import './styles/Header.scss';
import { NavLink } from "react-router-dom";

function Header() {

return (
<header className="container">
  {/* <a id="playground-scroll-link">Test out some experiments?</a> */}
  <div className="logo">
    <h1><NavLink to="/">karanmhatre</NavLink></h1>
  </div>
  <a href="mailto:me@karanmhatre.com">me@karanmhatre.com</a>
</header>

);
}

export default Header;
