import './styles/playground/grid-explorer.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from './three-js-projects/grid-explorer/art.js';

function HindiCharacter() {

  useEffect(() => {

    Art.init();

    document.getElementById('changeBtn').addEventListener('click', () => {
      if(document.getElementById('radius').value != '') {
        var radius = document.getElementById('radius').value;
        Art.radiusExp = radius;
      }
      
      Art.StartTime = Date.now();
    });

    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="grid-explorer-container art-container">
      <div className="content">
        <div className="controls">
          <h1>Grid Gen Art Explorer</h1>
          <p>Create artistic patterns and animations using simple math expressions to vary the radius of each circle. Change the value in the <b>Radius</b> input field.</p>
          <h3>Radius =</h3>
          <input type="text" name="radius" id="radius" placeholder="2" /><br />
          <button id="changeBtn">Change</button>
          <ul>
            <li>Add any Math expression in the input for radius. <br /><span>"2", "5", "20"</span></li>
            <li>Use variables <span>x</span>, <span>y</span>.<br /> <span>"x", "x+y"</span></li>
            <li>Using <span>time</span> will animate your creation. <br />
              <span> "time+x", "Math.sin(time)*8"</span>
            </li>
            <li>Here's a <a href="https://www.youtube.com/watch?v=8Lfrj7LLElQ" target="_blank">video</a> explaining some of the things you can create.</li>
            <li>Made by <a href="https://www.karanmhatre.com" target="_blank">karanmhatre.com</a></li>
          </ul>
        </div>
        <canvas id="paperCanvas" ></canvas>
      </div>
    </div>
  );
}

export default HindiCharacter;
