import paper from 'paper'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');
    
    this.canvas.style.height = (this.canvas.clientWidth+100) + "px";

    this.isAnimating = false;

    this.sizes = {
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight
    }  

    if(this.sizes.width < 500) {
      this.sizes.height +=100;
    }

    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];
    
    paper.setup(this.canvas);
    this.addBg();
    // this.renderSingleArt(0,0, this.sizes.width, this.sizes.height);
    this.drawManyArtworks();
    this.addText();
    paper.view.draw();
  }

  addText() {
    var text = new paper.PointText(new paper.Point(this.sizes.width/2, this.sizes.height/2));
    text.fillColor = 'white';
    text.fontSize = 36;
    text.fontFamily = 'Montserrat';
    text.fontWeight = 500;
    text.content = `having no destination \ni am never lost.`;
    text.blendMode = 'difference';

    if(this.sizes.width < 500) {
      text.fontSize = 28;
      text.position.x = (this.sizes.width/2);
      text.position.y = (this.sizes.height/2);
    }
    else {
      text.position.x = (this.sizes.width/2);
      text.position.y = (this.sizes.height/2)-30;
    }

  }

  addBg() {
    var rect = new paper.Path.Rectangle({
      point: [0, 0],
      size: [paper.view.size.width, paper.view.size.height],
    });
    rect.sendToBack();
    rect.fillColor = '#000';
  }

  drawManyArtworks() {
    var countX = 10;
    var countY = 10;

    if(this.sizes.width < 500) {
      countX = 5;
      countY = 10;
    }
    
    var xStep = (this.sizes.width/countX);
    var yStep = (this.sizes.height/countY);

    for(let i=0;i<countX;i++) {
      for(let j=0;j<countY-1;j++) {
        var startX = i*xStep;
        var startY = j*yStep;
        var endX = (i*xStep)+xStep;
        var endY = (j*yStep)+yStep; 
        this.renderSingleArt(startX, startY, endX, endY);
      }
    }
  }

  renderSingleArt(startX, startY, endX, endY) {
    
    var lineCount = this.getRandomInt(2,10);
    
    for(let i=0; i<lineCount;i++)
      this.drawLine(startX, startY, endX, endY);
  }

  drawLine(startX, startY, endX, endY) {

    var centerX = startX + ((endX-startX)/2);
    var spacingX = (endX-startX)/5;
    var spacingY = (endY-startY)/10;
    
    var path = new paper.Path({
      strokeColor: this.colors[this.getRandomInt(0,this.colors.length-1)]
    });

    var array = [];
    
    var segmentCount = this.getRandomInt(2,10);
    
    array[0] = [centerX, startY+spacingY];
    array[segmentCount] = [centerX, endY-(spacingY*2)];

    var previousY = startY + spacingY;

    for(let i = 1; i< segmentCount; i++) {
      var x1 = this.getRandomInt(centerX-spacingX, centerX+spacingX);
      var y1 = this.getRandomInt(previousY, endY-(spacingY*2));
      previousY = y1;
      array[i] = [x1,y1];
    }
    
    path.addSegments(array);
    
    path.smooth({ 
      type: 'catmull-rom' ,
      factor: 0.5
    });
    
  }

  animate() {

    if(this.isAnimating == true) {

      setTimeout(() => {

        for (var i = 0; i < paper.project.activeLayer.children.length; i++) {
  
          var selectedPath = paper.project.activeLayer.children[i];
          var selectedSegments = selectedPath.segments;
          
          for (var j = 1; j < selectedSegments.length-1; j++) {
            var segment = selectedPath.segments[j];
            // var sinus = Math.sin(event.time * 3 + i);
            segment.point.x += Math.sin(Date.now()) * 30;
            segment.point.y += Math.sin(Date.now()) * 30;
          }
        }
        
        requestAnimationFrame(() => {
          this.animate();
        });
  
      }, 1000/60);
    }
  }


  downloadImage() {
    var image = this.canvas.toDataURL();
    var aDownloadLink = document.createElement('a');

    aDownloadLink.download = 'poster-karanmhatre-dot-com-'+Date.now()+'.png';

    aDownloadLink.href = image;

    aDownloadLink.click();
  }

  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

}