import paper from 'paper'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  addBg() {
    var rect = new paper.Path.Rectangle({
      point: [0, 0],
      size: [paper.view.size.width, paper.view.size.height],
    });

    rect.sendToBack();
    rect.fillColor = '#FFC01D';
  }

  topLine() {
    var variability = 220;
    var start = [this.getRandomInt(0,variability), this.getRandomInt(0,variability)];
    var end = [paper.view.size.width-this.getRandomInt(0,variability), this.getRandomInt(0,variability)];
    
    var path = new paper.Path(start);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.lineTo(end);
  }

  centerLine() {
    var variability = 100;
    var start = [(paper.view.size.width/2)-this.getRandomInt(0,variability), this.getRandomInt(0,variability)];

    this.centerStart = start;

    var end = [(paper.view.size.width/2)-this.getRandomInt(0,variability), paper.view.size.height-this.getRandomInt(0,variability)];
    
    var end2 = [(paper.view.size.width/2)-this.getRandomInt(0,variability), paper.view.size.height-this.getRandomInt(0,variability)];

    var path = new paper.Path(start);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.strokeJoin = 'bevel';
    path.lineTo(end);
    path.lineTo(end2);
  }

  secondLine() {
    var variability = 100;
    var start =[this.centerStart[0]+this.getRandomInt(100,100+variability), this.centerStart[1]+this.getRandomInt(0,variability)];
    var end = [this.centerStart[0]+this.getRandomInt(100,100+variability), paper.view.size.height-this.getRandomInt(0,variability)];
    var end2 = [this.centerStart[0]+this.getRandomInt(100,100+variability), paper.view.size.height-this.getRandomInt(0,variability)];

    var path = new paper.Path(start);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.strokeJoin = 'bevel';
    path.lineTo(end);
    path.lineTo(end2);
  }

  va() {
    var path = new paper.Path.RegularPolygon([paper.view.size.width/2, (paper.view.size.height/2)], 4);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.strokeJoin = 'bevel';
    path.lineTo([100, (paper.view.size.height/2)-50]);
    path.lineTo([50, (paper.view.size.height/2)+100]);
    path.lineTo([150, (paper.view.size.height/2)+150]);
  }

  ka() {
    var variability = 100;
    var path = new paper.Path.RegularPolygon([paper.view.size.width/2, (paper.view.size.height/2)], 4);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.strokeJoin = 'bevel';
    path.lineTo([(paper.view.size.width)-this.getRandomInt(0,150), (paper.view.size.height/2)-50]);
    path.lineTo([(paper.view.size.width)-this.getRandomInt(0,50), (paper.view.size.height/2)+100]);
    path.lineTo([(paper.view.size.width)-this.getRandomInt(0,150), (paper.view.size.height/2)+150]);
  }

  ja() {
    var variability = 100;
    var path = new paper.Path([paper.view.size.width/2, (paper.view.size.height/2)]);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.strokeJoin = 'bevel';
    path.lineTo([(paper.view.size.width/2)-100, (paper.view.size.height/2)]);
    path.lineTo([(paper.view.size.width/2)-100, (paper.view.size.height/2)+100]);
    path.lineTo([(paper.view.size.width/2)-200, (paper.view.size.height/2)+150]);
    path.lineTo([(paper.view.size.width/2)-250, (paper.view.size.height/2)]);
    // path.lineTo([(paper.view.size.width)-this.getRandomInt(0,150), (paper.view.size.height/2)+150]);
  }

  aa() {
    var path = new paper.Path([100, 100]);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.strokeJoin = 'bevel';
    path.lineTo([(paper.view.size.width/2), (paper.view.size.height/2)]);
    path.lineTo([100, (paper.view.size.height/2)]);
    path.lineTo([(paper.view.size.width/2), (paper.view.size.height/2)+100]);
    path.lineTo([20, (paper.view.size.height/2)+200]);
  }

  dot() {
    var path = new paper.Path.Rectangle([this.getRandomInt(0,paper.view.size.width), this.getRandomInt(0,50)], 60);
    path.fillColor = '#000';
    path.rotate(30);
  }

  ee() {
    var path = new paper.Path([100, 100]);
    path.strokeWidth = this.getRandomInt(1,100);
    path.strokeColor = '#000';
    path.strokeJoin = 'bevel';
    path.lineTo([(paper.view.size.width/2), (paper.view.size.height/2)]);
    path.lineTo([100, (paper.view.size.height/2)]);
    path.lineTo([(paper.view.size.width/2), (paper.view.size.height/2)+100]);
  }


  generate() {
    paper.project.activeLayer.removeChildren();
    
    this.addBg();
    // this.text();
    
    this.topLine();
    this.drawRight();

    if(this.getRandomInt(0,10) < 7) {
      this.centerLine();

      if(this.getRandomInt(0,10) < 8) {
        this.secondLine();
      }
    }
  }

  drawRight() {
    if(this.getRandomInt(0,10) < 3) {
      this.va();
    }
    else if(this.getRandomInt(0,10) < 6) {
      this.ka();
    }
    else if(this.getRandomInt(0,10) < 6) {
      this.aa();
    } 
    else {
      this.ee();
    }
  }

  text() {
    var text = new paper.PointText(new paper.Point(10, 10));
    text.fillColor = '#000';
    text.content = "Hindi character generation by karanmhatre.com";
    text.position.x = paper.view.size.width-150;
    text.position.y = paper.view.size.height-20;
  }


}