import paper from 'paper'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getRandomColor() {
    return this.colors[this.getRandomInt(0, this.colors.length-1)]
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');
    this.canvas.style.height = this.canvas.clientWidth+"px";
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];

    this.textStyle = {
      fillColor: '#fff',
      strokeWidth: 2,
      strokeColor: '#0047FF',
      fontFamily: 'Arial',
      fontWeight: 'bold',
      opacity: 1,
      fontSize: 100
    };

    this.selectorStyle = {
      size: [50, 50],
      strokeColor: '#000',
      strokeWidth: 2,
      opacity: .04
    }

    this.word = document.getElementById('word').value.split('');
    
    this.textRender = [];

    paper.setup(this.canvas);
    this.addSelector();
    this.createDesign();
    paper.view.draw();
  }

  addBg() {
    var rect = new paper.Path.Rectangle({
      point: [0, 0],
      size: [paper.view.size.width, paper.view.size.height],
    });
    rect.sendToBack();
    rect.fillColor = '#fff';
  }

  addSelector() {
    this.selectorRect = null;

    paper.view.onMouseLeave = (event) => {
      if(this.selectorRect != null)
        this.selectorRect.remove();
    }

    paper.view.onMouseMove = (event) => {
      
      if(this.selectorRect != null)
        this.selectorRect.remove();
      
      this.selectorRect = new paper.Path.Rectangle({
        point: [event.point.x-25, event.point.y-25],
        ...this.selectorStyle
      });
    };
  }

  customDesign() {
    
    this.addBg();
    
    this.lastSegment = { x: null, y: null };

    this.counters = {
      newArrLetterCounter: 1,
      segmentCounter: -1,
      letterCounter: 0
    }

    var newLetter = [];

    this.currentLetter = null;

    paper.view.onClick = (event)=> {

      if(this.currentLetter == null) {
        this.currentLetter = new paper.PointText({
          point: [event.point.x-25, event.point.y+25],
          content: this.word[this.counters.letterCounter], ...this.textStyle,
        });
      }
      
      if(this.counters.segmentCounter > 2 ) {

        this.counters.letterCounter++;
        
        this.currentLetter = new paper.PointText({
          point: [event.point.x-25, event.point.y+25],
          content: this.word[this.counters.letterCounter], ...this.textStyle,
        });

        this.counters.segmentCounter = 0; 
        this.lastSegment = { x: null, y: null };
      }
      else {
        this.counters.segmentCounter++;
      }      

      if(this.lastSegment.x == null) {
        this.lastSegment.x = event.point.x-25;
        this.lastSegment.y = event.point.y+25;

        newLetter[0] = this.currentLetter.clone();
        this.counters.newArrLetterCounter = 1;
      }
      else {

        var newX = event.point.x-25;
        var newY = event.point.y+25;

        for(let i = 1;i<20; i++) {  
          newLetter[this.counters.newArrLetterCounter] = newLetter[this.counters.newArrLetterCounter-1].clone();
          newLetter[this.counters.newArrLetterCounter].fillColor = '#fff';
          newLetter[this.counters.newArrLetterCounter].point.x = this.lastSegment.x + ( ((newX-this.lastSegment.x)/20) * i);
          newLetter[this.counters.newArrLetterCounter].point.y = this.lastSegment.y + ( ((newY-this.lastSegment.y)/20) * i);
          this.counters.newArrLetterCounter++;
        }

        this.lastSegment.x = newX;
        this.lastSegment.y = newY;

        if(this.counters.segmentCounter == 3) {
          newLetter[newLetter.length-1].fillColor = '#0047FF';
        }
      }

    };
  }

  createDesign() {    

    this.addBg();

    this.path = [];

    var width = (this.canvas.clientWidth)/3;
    var height = (this.canvas.clientHeight)/2;

    var borders = [
      [[50, width],[50, height]],
      [[width, width*2],[50, height]],
      [[width*2, width*3],[50, height]],
      [[50, width],[height, height*2]],
      [[width, width*2],[height, height*2]],
      [[width*2, width*3],[height, height*2]],
    ];

    if (paper.view.size.width < 500) {
      this.textStyle.fontSize = 70;
    }

    for(let i=0;i<6;i++) {
      this.path[i] = new paper.Path({
        // strokeColor: 'black'
      });

      for(let j=0;j<4;j++) {
        this.path[i].add(
          [this.getRandomInt(
            borders[i][0][0], borders[i][0][1]
          ),
          this.getRandomInt(
            borders[i][1][0], borders[i][1][1]
          )]
        );
      }
    }

    this.word.forEach((letter, index) => {  
      this.textRender[index] = new paper.PointText({
        point: [this.getRandomInt(50, 500), this.getRandomInt(50, 500)],
        content: letter, ...this.textStyle
      });
    });

    this.textRender.forEach((letter, index) => {  
      var newLetter = [];

      var segments = this.path[index].segments;

      letter.position.x = segments[0].point.x;
      letter.position.y = segments[0].point.y;

      newLetter[0] = letter.clone();

      var counter = 1;

      for(let j=1; j < segments.length ; j++) {
        var oldSegment = segments[j-1].point;
        var newSegment = segments[j].point;
        
        for(let i = 1;i<15; i++) {  
          newLetter[counter] = newLetter[counter-1].clone();
          newLetter[counter].position.x = oldSegment.x + ( ((newSegment.x-oldSegment.x)/15) * i);
          newLetter[counter].position.y = oldSegment.y + ( ((newSegment.y-oldSegment.y)/15) * i);
          counter += 1;
        }
      }

      newLetter[newLetter.length-1].fillColor = '#0047FF';
      // newLetter[newLetter.length-1].fillColor = this.randomColorPicked;

    });
  }

  animate() {

    paper.view.onFrame = (event) => {
      // console.log(event.); 
    }

    paper.view.onClick = () => {
      // console.log(event.offsetX+","+event.offsetY);
    }
    
  }


  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

  downloadImage() {
    var image = this.canvas.toDataURL();
    var aDownloadLink = document.createElement('a');

    aDownloadLink.download = 'poster-karanmhatre-dot-com-'+Date.now()+'.png';

    aDownloadLink.href = image;

    aDownloadLink.click();
  }

}