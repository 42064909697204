import Scene from './scene-starter.js'
import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js'
import * as THREE from 'three'
import gsap from 'gsap'

export default new class {
  constructor() {
    
  }

  init() {
    this.canvas = document.getElementById('rocksWebgl');
    this.obj = Scene;

    this.rotate = true;

    this.rocks = [];
    
    this.obj.init(this.canvas);
    this.colorsArr = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];    

    this.clock = new THREE.Clock();

    this.addManyRocks();
    this.addLights();
    this.startTick();
  }

  addManyRocks() {

    var counter = 0;
    for(let i=0; i<9;i++) {      
      for(let j=0; j<5;j++) {
        this.addRock(counter);
        this.rocks[counter].position.x = (i*7)-(28);
        this.rocks[counter].position.y = (j*6)-(10);
        counter++;
      }
    } 

    this.obj.camera.position.set(0,0,40);
  }

  startTick() {

    const tick = () => {

      setTimeout(() => {

        var elapsedTime = this.clock.getElapsedTime();

        if(this.rotate) {
          for(let i=0; i<this.rocks.length;i++) {
            this.rocks[i].rotation.y = elapsedTime*5;
          }
        }

        this.obj.render();
        window.requestAnimationFrame(() => {
          tick();
        });

      }, 1000 / 60 );
    }

    tick();
  }

  changeRock(x) {

    const vertices = new Float32Array(this.generateVertices());
    this.rocks[x].geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
    this.rocks[x].material.color.set(new THREE.Color(this.colorsArr[this.getRandomInt(0,this.colorsArr.length-1)]));
  }

  generateVertices() {
    let uniqueVertices = [
      [],
      [1,1,1],
      [1,1,-1],
      [1,-1,-1],
      [1,-1,1],
      [-1,-1,1],
      [-1,1,1],
      [-1,1,-1],
      [-1,-1,-1],
    ];

    for(let i=1;i<uniqueVertices.length;i++) {
      for(let j=0;j<3;j++) {
        var randomN = (Math.random()*2)-1;
        if(uniqueVertices[i][j]<0)
          uniqueVertices[i][j] = uniqueVertices[i][j] - (randomN);
        else
          uniqueVertices[i][j] = uniqueVertices[i][j] + (randomN);
      }
    }

    let order = [
      2,1,3,
      3,1,4,
      1,2,7,
      1,7,6,
      1,6,5,
      4,1,5,
      6,7,8,
      6,8,5,
      7,2,8,
      8,2,3,
      8,4,5,
      4,8,3
    ];

    let finalArr = [];

    for(let i=0; i<order.length; i++) {
      finalArr = finalArr.concat(uniqueVertices[order[i]]);
    }

    return finalArr;
  }


  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  addRock(x) {
    const geometry = new THREE.BufferGeometry();
    const vertices = new Float32Array(this.generateVertices());
    geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
    geometry.computeVertexNormals();

    const material = new THREE.MeshStandardMaterial( { 
      color: new THREE.Color(this.colorsArr[this.getRandomInt(0,this.colorsArr.length-1)]),
      metalness: 0.6,
      roughness: 1,
    } );
    
    const mesh = new THREE.Mesh( geometry, material );
    mesh.receiveShadow = true;
    mesh.castShadow = true;
    
    this.rocks[x] = mesh;

    this.obj.scene.add(this.rocks[x]);

  }

  addLights() {
    const ambientLight = new THREE.HemisphereLight(0xffffff, 0xffffff,2);
    this.obj.scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0x0000ff, 0.2);
    pointLight.position.set(2,1,3);

    pointLight.castShadow = true;
    this.obj.scene.add(pointLight);
    
    const pointLight2 = new THREE.PointLight(0xff0000, 2);
    pointLight2.position.set(-2,-1,-3);

    pointLight2.castShadow = true;
    this.obj.scene.add(pointLight2);
  }


  removeAllElements() {
    this.obj.scene.clear();
    
    for( var i = this.obj.scene.children.length - 1; i >= 0; i--) { 
      var obj = this.obj.scene.children[i];
      
      if(obj.geometry !== undefined) {
        obj.geometry.dispose();
        obj.material.dispose();
      }
      else {
        obj.dispose();
      }
        
    }
    
    this.obj.renderer.dispose();
  }


}

