import '../styles/playground/closeToHome.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/close-to-home/art.js';

function CloseToHome() {

  useEffect(() => {

    Art.init();

    document.getElementById('downloadBtn').addEventListener('click', () => {
      Art.downloadImage();
    });
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="close-to-home-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / close to home</h3>
        <p>"Close to home" is both, comforting and uncomforting. Home is comforting, but if an art subject is too close to home, it makes you feel uncomfortable or upset because it is about a sensitive or very personal subject.<br></br><br></br>
        Split the canvas into a 10x10 grid. Draw a curve based on sine and cos values of the past time mod 10. If the distance between the start and end points of the line is greater 20 pixels, then restart the line.<br></br><br></br>
        You'll soon find some fun and unique curls that last longer than others.<br></br><br></br>
        Download pieces that you find interesting.
        </p>
        <ul className="options">
          <li><button id="downloadBtn">Download Image</button></li>
        </ul>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CloseToHome;
