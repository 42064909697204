import '../styles/playground/character-stretch.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/character-stretch/art.js';

function CharacterStretch() {

  useEffect(() => {

    document.title = 'Character Stretch | Karan Mhatre';

    Art.init();
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="character-stretch-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / character stretch</h3>
        <p>Exploration of the letter "A" through manipulation of its height, width, and crossbar position.<br></br><br></br>
        Use numbers 1-8 on your keyboard to control various characteristics of the letter "A".
        </p>
        <div className="gui-controls"></div>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CharacterStretch;
