import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'

export default new class {
  
  init(canvas) {

    this.canvas = canvas;

    this.sizes = {
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight
    }
    
    this.scene = new THREE.Scene();
    // this.gui = new dat.GUI();
    
    this.camera = new THREE.PerspectiveCamera(50, this.sizes.width/this.sizes.height);
    
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true
    });

    this.rendererSetup();

    this.clock = new THREE.Clock();
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    // this.controls = new OrbitControls(this.camera, this.canvas);
    // this.controls.enableZoom = false;
    // this.controls.enablePan = false;

    this.cloud = new THREE.Group();
    this.camera.position.set(0,0,10);

    this.addEvents();
  }

  rendererSetup() {
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  }

  render() {
    this.renderer.render(this.scene, this.camera);
    // this.controls.update();
  }

  addEvents() {
    
    window.addEventListener('resize', () => {
      this.resize()
    });
  }

  resize() {
    this.sizes.width = window.innerWidth;
    this.sizes.height = window.innerHeight;
    this.camera.aspect = this.sizes.width / this.sizes.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.render();
  }
}