import { gsap } from 'gsap';

function onEnter() {

  const tl = gsap.timeline();

  tl.from('.info h2 span', {
    y: 100,
    duration: 1,
    delay: 1.5,
    ease: 'power3.inOut',
    stagger: 0.3,
    skewY: 5
  });

  gsap.from('.info p', {
    opacity: 0,
    ease: 'power3.inOut',
    duration: 1,
    delay: 2
  });

  gsap.from('.line', {
    strokeDashoffset: 1000,
    duration: 2,
    ease: 'power3.inOut',
    delay: 3
  });
}

export default onEnter;

export const designs = [
  {
    id: "1",
    year: "2020",
    category: "interfaces",
    name: "Eubrics HR solution",
    thumbnail: "/v1624007914/Website/Interfaces/Frame_7592_pcrxmk.webp",
    text: "In 2020 I had a chance to work of designs made for Bloom HR Solutions. It is a new-age HR solution that helps employees not only get 360 feedback, but also provides resources help them improve on the feedback ",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1624290820/Website/Interfaces/eubrics-designs_wkmfeh.png",
        type: "img",
        text: "Dashboard designs"
      },
      {
        id: "2",
        src: "/v1624283755/Website/Case%20Studies/Bloom/design-system_gat0ub.png",
        type: "img",
        text: "Design Systems"
      },
    ]
  },
  {
    id: "4",
    year: "2020",
    category: "interfaces",
    name: "Nirali - Photography Website",
    thumbnail: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1624008277/Website/Interfaces/Comp_2_1_pym7cb.mp4",
    text: "",
    type: "vid",
    count: "3",
    sources: [{
      id: "1",
      src: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1623923298/Website/Interfaces/Comp_1_1_vthrxf.mp4",
      type: "vid",
      text: "Full View"
    },
    {
      id: "3",
      src: "/v1623923724/Website/Interfaces/Desktop_-_1_woefzq.webp",
      type: "img",
      text: "Home Page"
    },
    {
      id: "2",
      src: "/v1623923598/Website/Interfaces/Desktop_-_2_xbdjww.webp",
      type: "img",
      text: "Project Detail View"
    },]
  },
  {
    id: "3",
    year: "2020",
    category: "interfaces",
    name: "Moutain Leaderboard",
    thumbnail: "/v1623846312/Website/Interfaces/Frame_9435_g5cmyy.webp",
    text: "Leaderboard concept created for WhiteHatJR for their moutain league based leaderboard.",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1623846494/Website/Interfaces/Frame_9436_xnabjn.webp",
        type: "img",
        text: "Full view"
      }
    ]
  },
  {
    id: "2",
    year: "2020",
    category: "interfaces",
    name: "Student's Learning Journey",
    thumbnail: "/v1623075688/Website/Interfaces/Frame_7897_lufjww.webp",
    text: "I designed the interface and illustrations to help students understand (and get excited about) their learning journey on WhiteHatJR.",
    type: "img",
    count: "3",
    sources: [{
      id: "1",
      src: "/v1623846807/Website/Interfaces/Dribbble_Shot_-_5_badq0b.webp",
      type: "img",
      text: "Full View"
    }]
  },
  {
    id: "36",
    year: "2020",
    category: "interfaces",
    name: "Student Community",
    thumbnail: "/v1623936905/Website/Interfaces/community_ta1mgz.webp",
    text: "I designed the interface and illustrations to help students understand (and get excited about) their learning journey on WhiteHatJR.",
    type: "img",
    count: "3",
    sources: [{
      id: "1",
      src: "/v1623936904/Website/Interfaces/community-2_kwvuah.webp",
      type: "img",
      text: "Full View"
    },
    {
      id: "2",
      src: "/v1623936904/Website/Interfaces/community-3_lih7mx.webp",
      type: "img",
      text: "Card View"
    }]
  },
  {
    id: "37",
    year: "2020",
    category: "interfaces",
    name: "Big Black Burger - Mobile UI",
    thumbnail: "/v1624001477/Website/Interfaces/Burger/Frame_9444_nx0xuz.webp",
    text: "I designed the interface and illustrations to help students understand (and get excited about) their learning journey on WhiteHatJR.",
    type: "img",
    count: "3",
    sources: [{
      id: "1",
      src: "/v1624001477/Website/Interfaces/Burger/Frame_9454_vqmywn.webp",
      type: "img",
      text: "Zoomed in View"
    },
    {
      id: "2",
      src: "/v1624001477/Website/Interfaces/Burger/Frame_9453_mmlqrs.webp",
      type: "img",
      text: "All 3D Burger Models"
    },
    {
      id: "3",
      src: "/v1624001477/Website/Interfaces/Burger/Frame_9455_nlmd8l.webp",
      type: "img",
      text: "Big Regular Burger"
    }]
  },
  {
    id: "9",
    year: "2020",
    category: "3d",
    name: "Cloud",
    thumbnail: "/v1623312878/Frame_7917_xgvdxv.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "29",
    year: "2020",
    category: "3d",
    name: "Black Burger",
    thumbnail: "/v1623076592/Website/3D/Frame_7880_gus0fg.webp",
    text: "",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1624008466/Website/3D/Frame_9453_rigxnf.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "2",
        src: "/v1624008464/Website/3D/Frame_7596_yajvif.webp",
        type: "img",
        text: "Full View"
      }
    ]
  },
  {
    id: "12",
    year: "2021",
    name: "Type Animation 2",
    category: "motion-design",
    thumbnail: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1623133501/Website/Motion%20Design/Comp_1_owkoos.mp4",
    text: "",
    type: "vid",
    count: "3",
    sources: []
  },
  {
    id: "13",
    year: "2021",
    name: "Type Animation 1",
    category: "motion-design",
    thumbnail: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1623133494/Website/Motion%20Design/Comp_1_1_manrkq.mp4",
    text: "",
    type: "vid",
    count: "3",
    sources: []
  },
  {
    id: "14",
    year: "2021",
    name: "Shape Animation 1",
    category: "motion-design",
    thumbnail: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1623139207/Website/Motion%20Design/Comp_1_o9kjay.mp4",
    text: "",
    type: "vid",
    count: "3",
    sources: []
  },
  {
    id: "37",
    year: "2021",
    name: "Screen Sharing Loader",
    category: "motion-design",
    thumbnail: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1623938004/Website/Interfaces/Comp_2_rv0zaf.mp4",
    text: "",
    type: "vid",
    count: "3",
    sources: []
  },
  {
    id: "38",
    year: "2021",
    name: "Coding Cat Loader",
    category: "motion-design",
    thumbnail: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1623938367/Website/Motion%20Design/cat-computer_3_gzcu8f.mp4",
    text: "",
    type: "vid",
    count: "3",
    sources: []
  },
  {
    id: "39",
    year: "2021",
    name: "Badge Celebration",
    category: "motion-design",
    thumbnail: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1624005305/Website/Motion%20Design/Comp_1_1_bf88dp.mp4",
    text: "",
    type: "vid",
    count: "3",
    sources: []
  },
  {
    id: "15",
    year: "2020",
    category: "paintings",
    name: "Japan",
    thumbnail: "/v1623076327/Website/Paintings/Japan/Frame_7900_sqp4nh.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "16",
    year: "2020",
    category: "paintings",
    name: "Hampi (Acrylic 12'x14')",
    thumbnail: "/v1623076446/Website/Paintings/Frame_7912_g2cihv.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "17",
    year: "2020",
    category: "paintings",
    name: "Kerala",
    thumbnail: "/v1623076377/Website/Paintings/Frame_7899_rtnitk.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "18",
    year: "2020",
    category: "paintings",
    name: "At Home",
    thumbnail: "/v1623076382/Website/Paintings/Frame_7905_xmfwqn.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "19",
    year: "2020",
    category: "paintings",
    name: "Charcoal Portraits",
    thumbnail: "/v1623076393/Website/Paintings/Frame_7909_vbqasm.webp",
    text: "",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1624007049/Website/Paintings/Frame_7910_tydrj3.webp",
        type: "img",
        text: "Charcoal Portrait"
      },
      {
        id: "2",
        src: "/v1624007049/Website/Paintings/Frame_7911_qriuph.webp",
        type: "img",
        text: "Charcoal Portrait"
      },
    ]
  },
  {
    id: "20",
    year: "2020",
    category: "paintings",
    name: "Postcards from Goa",
    thumbnail: "/v1623076418/Website/Paintings/Frame_7901_whqkdw.webp",
    text: "",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1624006955/Website/Paintings/Frame_7904_vbqv3o.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "2",
        src: "/v1624006954/Website/Paintings/Frame_7903_ykfewp.webp",
        type: "img",
        text: "Full View"
      }
    ]
  },
  {
    id: "21",
    year: "2020",
    category: "digital-illustrations",
    name: "Posters",
    thumbnail: "/v1623076211/Website/Digital%20Illustrations/Frame_7885_ti3slv.webp",
    text: "Some posters I made in 2020 to practice drawing on the iPad.",
    type: "img",
    count: "3",
    sources: [
      {
        id: "4",
        src: "/v1623076211/Website/Digital%20Illustrations/Frame_7885_ti3slv.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "2",
        src: "/v1623076148/Website/Digital%20Illustrations/Frame_7889_kxpfyq.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "4",
        src: "/v1623076038/Website/Digital%20Illustrations/Frame_7890_rpucnp.webp",
        type: "img",
        text: "Full View"
      }
    ]
  },
  {
    id: "41",
    year: "2020",
    category: "digital-illustrations",
    name: "Posters Part 2",
    thumbnail: "/v1623076168/Website/Digital%20Illustrations/Frame_7886_ayibbo.webp",
    text: "Some posters I made in 2020 to practice drawing on the iPad.",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1623076168/Website/Digital%20Illustrations/Frame_7886_ayibbo.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "3",
        src: "/v1623076195/Website/Digital%20Illustrations/Frame_7891_pgioy2.webp",
        type: "img",
        text: "Full View"
      },
    ]
  },
  {
    id: "25",
    year: "2020",
    name: "Swiggly Humans",
    category: "digital-illustrations",
    thumbnail: "/v1623076033/Website/Digital%20Illustrations/Frame_7888_aq35ia.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "27",
    year: "2020",
    name: "Travel Illustrations",
    category: "digital-illustrations",
    thumbnail: "/v1623076109/Website/Digital%20Illustrations/Frame_7887_pm4jd6.webp",
    text: "",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1623076066/Website/Digital%20Illustrations/Frame_7892_ygz2wm.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "2",
        src: "/v1623076109/Website/Digital%20Illustrations/Frame_7887_pm4jd6.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "3",
        src: "/v1623076112/Website/Digital%20Illustrations/Frame_7893_jexe4p.webp",
        type: "img",
        text: "Full View"
      }
    ]
  },
  {
    id: "40",
    year: "2020",
    name: "Kids website badges",
    category: "digital-illustrations",
    thumbnail: "/v1623076193/Website/Digital%20Illustrations/Frame_7895_mcf59x.webp",
    text: "",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        src: "/v1623076193/Website/Digital%20Illustrations/Frame_7895_mcf59x.webp",
        type: "img",
        text: "Full View"
      },
      {
        id: "2",
        src: "/v1623076192/Website/Digital%20Illustrations/Frame_7898_jjqddh.webp",
        type: "img",
        text: "Full View"
      }
    ]
  },
  {
    id: "30",
    year: "2020",
    category: "3d",
    name: "The Shiny Room",
    thumbnail: "/v1624007747/Website/Digital%20Illustrations/room_mubypo.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "10",
    year: "2020",
    name: "Isosphere",
    category: "3d",
    thumbnail: "/v1623151958/Website/3D/isosphere_fpdttk.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "42",
    year: "2020",
    category: "3d",
    name: "Kilimanjaro",
    thumbnail: "/v1623312878/Frame_7922_bnzi6q.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "31",
    year: "2020",
    category: "3d",
    name: "Ball",
    thumbnail: "/v1623312878/Frame_7920_ftlftr.webp",
    text: "",
    type: "img",
    count: "3",
    sources: []
  },
  {
    id: "33",
    year: "2020",
    category: "case-studies",
    name: "A New Classroom Experience",
    thumbnail: "/v1624289296/Website/Case%20Studies/Classroom/hero_uw0t2v.png",
    text: "Designed a new classroom experience that helped WhiteHatJR improve the student’s learning experience, and launch a new business line.",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        heading: "opportunity",
        type: "text",
        text: `
        <p>WhiteHatJR has so far been providing one to one live interactive classes for kids. In these classes a teacher would teach a single student at a time.</p>
        <p>Since this experience is one-to-one it is also expensive.</p>
        <p>We wanted to launch a product that will cater to a more price-sensitive audience, and at the same time not overburden the teacher, so that the one-to-one learning quality can be maintained.</p>
        <p>Few critical features that were needed for this solution were - Improved Classroom controls, Requesting Screenshare from students, Slideshows, and Breakout Rooms.</p>`
      },
      {
        id: "2",
        heading: "design challenge",
        type: "text",
        text: `
        <p>WhiteHatJr’s key differentiator in the market has always been personalised learning. We were not willing to comprise on this while scaling up to a one-to-four classroom experience.</p>
        <p>The teacher should be able to keep an eye on multiple kids at a time while providing a personalised experience to the kids wherever needed, since not all kids will learn at the same pace.</p>
        <p>Teachers were already comfortable with the exisiting classroom product that they’d been using for one-to-one teaching. Hence, this new solution should remain as close to the original as possible to reduce the learning curve.</p>`
      },
      {
        id: "3",
        heading: "research",
        type: "text",
        text: `
        <p>Market research had already identified the need for this solution to improve our market share.</p>
        <p>The emphasis of our user research was not on understanding whether this feature was required or not, but on how it can be executed well.</p>
        <p>The first set of one-to-many classes were conducted over Zoom to understand the vaiability of the solution and gain learning on what teachers needed while teaching a larger audience.</p>
        <p>Since your students build apps, I also conducted a product design session with 12 students to help them understand design principles. This helped me tremendosly in understanding the painpoints of managing an online classroom of kids.</p>`
      },
      {
        id: "4",
        heading: "learnings",
        type: "text",
        text: `
        <p>We realised that students were not well equiped to handle classroom controls such as muting-unmuting themselves, and this led to some distractions in the class. We decided to keep the student controls to a minimum and let the teacher guide the controls from their side instead to minimise the need to non-learning related conversations.</p>
        <p>The teacher needed to help out individual students with their assignment when they were stuck, and during this the other students would get distracted. We needed some form of breakout rooms where the teacher could have personal conversations with a few selected students.</p>
        <p>Students had a difficult time understanding how to share their screen. This was necessary since the teacher needed to review the work being done by the kids.</p>`
      },
      {
        id: "5",
        heading: "student classroom wireframes",
        type: "text",
        text: `
        <p>As previously decided, we wanted to keep minimum controls on the student interface. The focus of the student should always be on the teacher or the slides that are being presented. Everything else was secondary.</p>
        <p>If the student was having connection issues, or if the teacher disconnected from the class, then the student would get addtional functionality. But this was to be presented contextually.</p>`
      },
      {
        id: "6",
        src: "/v1624289556/Website/Case%20Studies/Classroom/student-wireframe_ikx7p0.png",
        type: "img",
        text: "Student Classroom Dashboard Wireframe"
      },
      {
        id: "7",
        heading: "teacher classroom wireframes",
        type: "text",
        text: `
        <p>While the student side was minimal, the teacher side was packed with features. We tried and simplified the UI with a smart grouping of controls.</p>
        <p>In typical video conferencing solutions, all users get to chose what to view. The user cna choose to view a particular participant, or a screen presented by someone, or choose to view a gallery view of all the cameras.</p>
        <p>But in our case we wanted the teacher to toggle what was being shown to the student at all times. This allowed the teacher to be in complete control of the experience.</p>`
      },
      {
        id: "8",
        src: "/v1624289300/Website/Case%20Studies/Classroom/teacher-wireframe_wnkreq.png",
        type: "img",
        text: "Student Classroom Dashboard Wireframe"
      },
      {
        id: "9",
        src: "/v1624289302/Website/Case%20Studies/Classroom/teacher-components_lgb7gm.png",
        type: "img",
        text: "Teacher Classroom Components"
      },
      {
      id: "10",
      heading: "requesting screenshare",
      type: "text",
      text: `
      <p>We enabled the teacher to request a student to share their screen. This would result in a pop-up (coach mark) on the student’s side, making it easy for the student to share their screen.</p>`
      },
      {
        id: "11",
        src: "/v1624289297/Website/Case%20Studies/Classroom/student-screenshare_rcddmb.png",
        type: "img",
        text: "Student receives a request for screenshare - Coach marks"
      },
      {
        id: "13",
        heading: "breakout rooms",
        type: "text",
        text: `
        <p>To enable the teacher to provide a personalised experience to students we decided to introduce breakout rooms. A breakout room is a room the teacher can create to have private discussion with a selected few students.</p>
        <p>This is important to resolve doubts and queries, as well as help students that are lagging in their class activities.</p>
        <p>While in the breakout room it was necessary for the teacher to still monitor the main classroom.</p>`
      },
      {
        id: "14",
        src: "/v1624289296/Website/Case%20Studies/Classroom/breakout-room-userflow_jwaqde.png",
        type: "img",
        text: "Teacher Breakout Room User Flow"
      },
      {
        id: "15",
        src: "/v1624289298/Website/Case%20Studies/Classroom/student-breakoutroom_nlkknc.png",
        type: "img",
        text: "Teacher Breakout Room Wireframe"
      },
      {
        id: "16",
        src: "/v1624289298/Website/Case%20Studies/Classroom/student-breakoutroom_nlkknc.png",
        type: "img",
        text: "Student Breakout Room"
      },
      {
        id: "17",
        src: "/v1624289298/Website/Case%20Studies/Classroom/student-breakoutroom-2_l5vr4v.png",
        type: "img",
        text: "Student Breakout Room - 2"
      },
      {
        id: "18",
        heading: "slideshow",
        type: "text",
        text: `
        <p>We enabled the teacher to request a student to share their screen. This would result in a pop-up (coach mark) on the student’s side, making it easy for the student to share their screen.</p>`
      },
      {
        id: "19",
        src: "/v1624289301/Website/Case%20Studies/Classroom/teacher-slides_gfbz45.png",
        type: "img",
        text: "Teacher Presenting Slides"
      },
      {
        id: "20",
        heading: "giving rewards to multiple students",
        type: "text",
        text: `
        <p>We enabled the teacher to request a student to share their screen. This would result in a pop-up (coach mark) on the student’s side, making it easy for the student to share their screen.</p>`
      },
      {
        id: "21",
        src: "/v1624289296/Website/Case%20Studies/Classroom/hats-off_ka9onc.png",
        type: "img",
        text: "Teacher Presenting Slides"
      },
      {
        id: "22",
        heading: "visual design",
        type: "text",
        text: `
        <p>The visual designs were completed with the help of teh central design team to make sure that there was consistency in the visual language.</p>`
      },
      {
        id: "24",
        src: "/v1624289305/Website/Case%20Studies/Classroom/student-ui_kwur1t.png",
        type: "img",
        text: "Teacher Presenting Slides"
      },
      {
        id: "25",
        src: "/v1624289300/Website/Case%20Studies/Classroom/teacher-ui_bn3phz.png",
        type: "img",
        text: "Teacher Presenting Slides"
      },
      {
        id: "26",
        heading: "impact",
        type: "text",
        text: `
        <p>Using this feature WhiteHatJr was able to launch a new business vertical that attract a significantly large new userbase.</p>
        <p>This helped us stay ahead of our competition by providing a superior classroom experience to the student and teacher.</p>`
      },
    ]
  },
  {
    id: "34",
    year: "2020",
    category: "case-studies",
    name: "Gamification and weekly leaderboard",
    thumbnail: "/v1623584625/Website/Case%20Studies/Leaderboard/hero_spovtq.webp",
    text: "Designing a level based leaderboard that helped WhiteHatJR improve retention and engagement of students on the platform.",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        heading: "opportunity",
        type: "text",
        text: `
        <p>There existed a weekly leaderboard that was based on points collected by student by doing various activities on the platform such as completing assignments and taking classes.</p><p>

        The problem with this leaderboard was that once a student had won on the leaderboard and won a badge, there wasn’t much for them to look forward to.</p><p>
        
        We wanted to launch a new leaderboard that gave the student a sense of progression and kept them wanting more even after winning.</p>`
      },
      {
      id: "2",
      heading: "design challenge",
      type: "text",
      text: `
      <p>In the new leaderboard we wanted to create a leveling system, where each week that the student finished in the top 10 of the leaderboard, then the student was moved to a higher league.</p><p>

      The challenge was to simplify and explain the workings of this leaderboard and make sure the students knew exactly how to win and progress through these levels.</p><p>
      
      The other challenge was to make each level and the narrative engaging enough for students to want to move to higher levels.</p>`
      },
      {
        id: "3",
        heading: "research",
        type: "text",
        text: `
        <p>We made some quick mockups and reached out to students.</p>

        <p>Student interviews revealed two major problems,</p>
        <ul>
        <li>
        Students failed to understand that they needed to maintain their position in the top 10 till the end of the week. The assumption was that as soon as they entered the top 10 they would be moved to the next level.</li>
        
        <p>We iterated over the mockups and content a few more times and tested with students until we were satisfied with the layout and direction.</p>

        <p>Learnings and decisions based on the reasearch,</p>
        <ul>
        <li>Introduce onboarding slides to build the narrative for the student</li>
        <li>Finalising on the theme of “Mountains”, since students found it interesting and clearly understood progression across the levels.</li>`
      },
      {
        id: "4",
        src: "/v1623584266/Website/Case%20Studies/Leaderboard/initial-iterations_zpnfxo.webp",
        type: "img",
        text: "Initial Iterations"
      },
      {
        id: "5",
        src: "/v1623584501/Website/Case%20Studies/Leaderboard/states_w84dwo.webp",
        type: "img",
        text: "States"
      },
      {
        id: "6",
        src: "/v1623584501/Website/Case%20Studies/Leaderboard/final-ui_upnvws.webp",
        type: "img",
        text: "Leaderboard Final UI"
      },
      {
      id: "7",
      heading: "impact",
      type: "text",
      text: `
      <p>The emphasis of research was not on understanding whether this feature was required or not, but on how it can be executed well.</p>
      <p>The first set of one-to-many classes were conducted over Zoom to understand the vaiability of the solution and gain learning on what teachers needed while teaching a larger audience.</p>`
      },
      {
        id: "8",
        heading: "next steps",
        type: "text",
        text: `
        <p>The emphasis of research was not on understanding whether this feature was required or not, but on how it can be executed well.</p>
        <p>The first set of one-to-many classes were conducted over Zoom to understand the vaiability of the solution and gain learning on what teachers needed while teaching a larger audience.</p>`
        },
    ]
  },
  {
    id: "35",
    year: "2020",
    category: "case-studies",
    name: "Eubrics 360 feedback SAAS",
    thumbnail: "/v1624283759/Website/Case%20Studies/Bloom/hero_nvuoov.png",
    text: "Designed the end-to-end experience and interface for a new-age HR solution that focused on 360 feedback and individual growth.",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        heading: "opportunity",
        type: "text",
        text: `
        <p>360 feedback solutions that exist today tend to do more for management and less for the employee using it. We wanted to shift this paradigm since the most value is found when the employee can understand how to implement the given feedback and improve.</p>
        <p>There were four major phases to the product,</p>
        <p>Assessment - To understand the user’s strengths and weaknesses</p>
        <p>Education - To allow the user to consume curated content on their own.</p>
        <p>Exposure - To allow the user to connect with others and get mentored</p>
        <p>Experience - To allow user to implement what they had learned in a real-world scenarios.</p>
        `
      },
      {
        id: "2",
        heading: "design challenges",
        type: "text",
        text: `
        <p>All the phases of the product needed to be clearly communicated to the user to ensure that they always knew what to expect.</p>
        <p>The instructions for the assessment needed to be clearly explained to the user, and the result of the assessment had to be presented in a layman manner.</p>
        <p>A design system was needed to reduce the development time and maintain consistency across the platform.</p>`
      },
      {
        id: "3",
        heading: "brainstorming and user flows",
        type: "text",
        text: `
        <p>The product started with very open-ended brainstorming of the problem statement. We added ideas, solutions, and inspirations into a board.</p>
        <p>I then used this board to illustrate the vision for the product in terms of user flows.</p>
        <p>This helped scope out the complete project, and helped create a singular understanding of the product among everyone (founders and devs).</p>
        <p>We were mindful of the time taken in this step so as to not create any unnecessary artifacts, and focus on creating practical, usable, design assets.</p>
        <p>At the end of this phase, developers were able to start architecting the database and creating the first set of APIs.</p>`
      },
      {
        id: "4",
        src: "/v1624283759/Website/Case%20Studies/Bloom/userflow-1_kf8ehw.png",
        type: "img",
        text: "User Flow"
      },
      {
        id: "5",
        src: "/v1624283760/Website/Case%20Studies/Bloom/userflow-2_djozdn.png",
        type: "img",
        text: "Example: Detailed Onboarding Flow"
      },
      {
        id: "6",
        heading: "wireframing and prototyping",
        type: "text",
        text: `
        <p>We took most time in this stage. We wanted to be extremely thorough in this stage to make sure that we were building consistent and scalable systems.</p>
        <p>We constantly reiterated through the wireframes and ensured that any new design element was thoroughly vented and reasoned for.</p>
        <p>We started by creating a broad framework for the home page that adapted as the user went through the various stages of the program.</p> 
        <p>Each section had it propose that was clearly defined so that the section was not misused in the future and the user knew what to expect always.</p>
        <p>Since this was a corporate tool, we took a desktop-only approach.</p>`
      },
      {
        id: "7",
        src: "/v1624283761/Website/Case%20Studies/Bloom/wireframe-1_j16jla.png",
        type: "img",
        text: "Wireframe of the overall framework"
      },
      {
        id: "8",
        heading: "building the assessments",
        type: "text",
        text: `
        <p>One critical piece that would determine the success of the product would be the assessments phase.</p>
        <p>During this phase, the user would fill a bunch of forms in order to understand themselves better. This data would determine the educational content that would be shown to the user, as well as match them with the relevant users in the organisation to improve more.</p>
        <p>The design challenge was to make these forms more interesting so that there would be good adoption of the product.</p>
        <p>Since this was the first phase in their journey we wanted to make a good impact and provide hand holding through this journey.</p>
        <p>There were 6 different assessments that the user had to take. We wanted to create a framework that worked across these so we could have consistency and save on dev time.</p>
        <p>The broad framework we managed to keep consistent for all the assessments.</p>
        `
      },
      {
        id: "9",
        src: "/v1624283762/Website/Case%20Studies/Bloom/wireframe-2_gv5jyt.png",
        type: "img",
        text: "Wireframe of the assessment framework"
      },
      {
        id: "10",
        src: "/v1624283770/Website/Case%20Studies/Bloom/assessment-questions_nxhmnr.png",
        type: "img",
        text: "All assessment question types and designs"
      },
      {
        id: "11",
        heading: "making assessments easy",
        type: "text",
        text: `
        <p>Instead of simply taking the HR forms and converting them to digital forms, I wanted to make proper use of our interactive web medium.</p>
        <p>One just example is given below. The first is the question as it appears in the HR form. And below is the design solution.</p>
        <p>There is also a workable prototype I created to better explain the concept.</p>
        `
      },
      {
        id: "12",
        src: "/v1624283760/Website/Case%20Studies/Bloom/asessments-example_kh6hgy.png",
        type: "img",
        text: "Making assessment questions easier"
      },
      {
        id: "13",
        src: `
          <iframe height="370" style="width: 100%;" scrolling="no" title="Assessment - Interaction" src="https://codepen.io/karanmhatre2/embed/oNZQLae?height=370&theme-id=light&default-tab=result" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">See the Pen <a href='https://codepen.io/karanmhatre2/pen/oNZQLae'>Assessment - Interaction</a> by Karan (<a href='https://codepen.io/karanmhatre2'>@karanmhatre2</a>) on <a href='https://codepen.io'>CodePen</a>.</iframe>
        `,
        type: "full-html",
        text: "Assessment Question Interaction"
      },
      {
        id: "14",
        heading: "visual design",
        type: "text",
        text: `
        <p>Since this was a new product, there hadn’t been any branding exercise done before. To tackle this problem, I created a moodboard and had the founders pick the directions that most appealed to them and their close group of collegues (who would be the early users of this product).</p>
        `
      },
      {
        id: "15",
        src: "/v1624283775/Website/Case%20Studies/Bloom/moodboard_ith5pb.png",
        type: "img",
        text: "Moodboard"
      },
      {
        id: "16",
        heading: "components and states",
        type: "text",
        text: `
        <p>I wanted to make sure right from the start that there was maximum reusability of components and consistency in the UI wherever possible.</p>
        <p>I made sure that minimal number of new components we added on as we tackled each usecase.</p>
        <p>I made full use of Figma’s Component building capabilities to ensure that any designer could build on this system in the future and use existing components with ease.</p>
        `
      },
      {
        id: "17",
        src: "/v1624283754/Website/Case%20Studies/Bloom/components_irhxqz.png",
        type: "img",
        text: "Components and States"
      },
      {
        id: "18",
        src: "/v1624283755/Website/Case%20Studies/Bloom/design-system_gat0ub.png",
        type: "img",
        text: "Eubrics Design System"
      },
      {
        id: "19",
        heading: "final designs",
        type: "text",
        text: `
        <p>The final step was creating all the pages and assets for the development team. We also made Figma the single source of truth for the developers.</p>
        <p>I’ve added some of the screens from the final product below.</p>
        `
      },
      {
        id: "20",
        src: "/v1624283758/Website/Case%20Studies/Bloom/login_olz35t.png",
        type: "img",
        text: "Login Page"
      },
      {
        id: "21",
        src: "/v1624283757/Website/Case%20Studies/Bloom/home-page_zr9otl.png",
        type: "img",
        text: "Dashboard Home Page"
      },
      {
        id: "22",
        src: "/v1624283757/Website/Case%20Studies/Bloom/assessment-single-question-2_tynsys.png",
        type: "img",
        text: "Assessment Question Page"
      },
      {
        id: "23",
        src: "/v1624283754/Website/Case%20Studies/Bloom/assessment-report_tp7wy0.png",
        type: "img",
        text: "Assessment Report Card"
      },
      {
        id: "24",
        src: "/v1624283766/Website/Case%20Studies/Bloom/community_qx9voq.png",
        type: "img",
        text: "Learning Community"
      },
      {
        id: "25",
        src: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1624288683/Website/Case%20Studies/Bloom/eubrics-interactions_qfgqja.mp4",
        type: "vid",
        text: "Micro Interactions"
      },
      {
        id: "26",
        heading: "impact",
        type: "text",
        text: `
        <p>The product was designed in 2 months from concept to developer ready assets.</p>
        <p>The process that we followed allowed us to unblock developers very soon in the journey so was to minimum are to market speed.</p>
        <p>The product is currently in Beta. The founders are onboarding their first set of 200 employees on the platform.</p>
        `
      },
    ]
  },
  {
    id: "44",
    year: "2021",
    category: "case-studies",
    name: "Music Box",
    thumbnail: "/v1624780758/Website/Case%20Studies/Music%20Box/hero_og4vfs.webp",
    text: "Implementing an interactive music box on the web",
    type: "img",
    count: "3",
    sources: [
      {
        id: "1",
        heading: "initial inspiration",
        type: "text",
        text: `
        <p>In 2019 I returned from a trip to Japan. I happened to visit a Music box shop in Kyoto that had over a thousand different variations of music boxes. I ended up buying one from there and I loved the soothing sound that this simple mechanical instrument was able to create.</p>
        `
      },
      {
        id: "2",
        heading: "concept",
        type: "text",
        text: `
        <p>I wanted to create a similar digital music box.</p>
        <p>I wanted to make it interactive, where the user could make tweeks that altered the music being played.</p>
        <p>I wanted it to retain the orginal dynamics/physics of the instrument and keep it as organic as possible.</p>
        `
      },
      {
        id: "3",
        heading: "approach and challenges",
        type: "text",
        text: `
        <p>I decided to create this using THREE JS.</p>
        <p>I needed to work out the physics engine.</p>
        <p>I needed to understand collision detection.</p>
        <p>I needed to understand hinge mechanics that would replicate the musical plates in the original device.</p>
        `
      },
      {
        id: "4",
        src: "/v1624780689/Website/Case%20Studies/Music%20Box/drawings_j7kuvd.webp",
        type: "img",
        text: "Initail drawing and ideation"
      },
      {
        id: "5",
        src: "https://res.cloudinary.com/dgksx9vlc/video/upload/v1624780701/Website/Case%20Studies/Music%20Box/practice_rqsddy.mp4",
        type: "vid",
        text: "Activating the dynamics in THREEJS"
      },
      {
        id: "6",
        heading: "final version",
        type: "text",
        text: `
        <p>I finallly have a working music box. </p>
        All though it sounds terrible at this stage. </p> 
        I have the gravity, collision detection, music production, and controls working fine. </p>
        It’s time to fix the music and asthetics.</p>
        `
      },
      {
        id: "7",
        heading: "planned improvements",
        type: "text",
        text: `
        <p>First I need to make each plate hit the bell only once per rotation.</p>
        The I need to pick better musical notes.</p>
        Then I need to pick a musical composition to replicate.</p>
        `
      },
    ]
  },
];