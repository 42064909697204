import './App.scss';

// Components
import Header from './Header'
import Hero from './home/Hero'
import Links from './home/Links'

// import Links from './home/Links'
import ImageSlider from './home/ImageSlider'
import Footer from './home/Footer'
import PlayGroundLinks from './home/PlayGroundLinks'
import Art from './three-js-projects/anti-matter/art.js';
import Rocket from './three-js-projects/rocketship/art.js';

// Utils
import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';

function Home() {

  useEffect(() => {

    window.scrollTo(0, 0);
    
    gsap.registerPlugin(ScrollTrigger);

    Art.init();
    Rocket.init();
    
    ScrollTrigger.create({
      trigger: "#rocketship",
      start: "-=800",
      end: '+=1200',
      scrub: true,
      onUpdate: self => {
        Rocket.rocket.rotation.x = self.progress.toFixed(3)*3;
      }
    });

    return () => {
      Art.removeAllElements();
      Rocket.removeAllElements();
    }

  }, []);

  return (
    <div className="home">
      <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y:0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.8 }}
        >
        <Header/>
        <Hero />
        <Links/>
        <ImageSlider />
        <PlayGroundLinks />
        <Footer />
      </motion.div>
    </div>
  );
}

export default Home;