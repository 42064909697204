import './styles/Playground.scss'

import { useEffect } from 'react';
import {BrowserRouter as Switch, Route} from 'react-router-dom';
import AntiMatter from './playground/AntiMatter'
import MusicBox from './playground/MusicBox'
import LetItRain from './playground/LetItRain'
import Mountain from './playground/Mountain'
import ShyCircles from './playground/ShyCircles'
import SpaceTravel from './playground/SpaceTravel'
import GenerativeRocks from './playground/GenerativeRocks'
import Dots from './playground/Dots'
import Entanglement from './playground/Entanglement'
import ImpossiblePuzzle from './playground/ImpossiblePuzzle';
import CharacterStretch from './playground/CharacterStretch';
import Waves from './playground/Waves';


import { NavLink } from "react-router-dom";
import { motion } from 'framer-motion';
import LetterFall from './playground/LetterFall';
import CloseToHome from './playground/CloseToHome';
import Strings from './playground/Strings';
import FlowField from './playground/FlowField';
import ColorBeats from './playground/ColorBeats';
import HindiCharacter from './playground/HindiCharacter';


function Playground() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="playground-container">
      <motion.div
      exit={{ opacity: 0 }}
      transition={{ duration: 0 }}
    ></motion.div>

      <div className="sidebar">
        <h3><span>karanmhatre</span><br />playground</h3>
        <ul>
          <li><NavLink activeClassName="selected" to="/playground/character-stretch">character stretch</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/hindi-characters">hindi letters</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/color-beats">color beats</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/flow-field">flow field</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/strings">strings</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/wave-jungle">wave jungle</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/close-to-home">close to home</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/letter-fall">letter fall</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/impossible-puzzle">impossible puzzle</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/entanglements">entanglements</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/dots-and-dashes">dots &amp; dashes</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/generative-rocks">generative rocks</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/music-box">Music Box</NavLink></li>
          <li><NavLink activeClassName="selected" to="/playground/anti-matter">Anti-Matter</NavLink></li>
        </ul>
      </div>

      <div className="piece">
        <Route path='/playground/character-stretch' exact >
          <CharacterStretch />
        </Route>
        <Route path='/playground/' exact >
          <HindiCharacter />
        </Route>
        <Route path='/playground/hindi-characters' exact >
          <HindiCharacter />
        </Route>
        <Route path='/playground/color-beats' exact >
          <ColorBeats />
        </Route>
        <Route path='/playground/flow-field' exact >
          <FlowField />
        </Route>
        <Route path='/playground/strings' exact >
          <Strings />
        </Route>
        <Route path='/playground/wave-jungle' exact >
          <Waves />
        </Route>
        <Route path='/playground/close-to-home' exact >
          <CloseToHome />
        </Route>
        <Route path='/playground/letter-fall' exact >
          <LetterFall />
        </Route>
        <Route path='/playground/mountains' exact >
          <Mountain />
        </Route>
        <Route path='/playground/impossible-puzzle' exact >
          <ImpossiblePuzzle />
        </Route>
        <Route path='/playground/generative-rocks' exact >
          <GenerativeRocks />
        </Route>
        <Route path='/playground/entanglements' exact >
          <Entanglement />
        </Route>
        <Route path='/playground/dots-and-dashes' exact >
          <Dots />
        </Route>
        <Route path='/playground/music-box' exact >
          <MusicBox />
        </Route>
        <Route path='/playground/anti-matter' exact >
          <AntiMatter />
        </Route>
        <Route path='/playground/let-it-rain' exact >
          <LetItRain />
        </Route>
        <Route path='/playground/space-travel' exact >
          <SpaceTravel />
        </Route>
        <Route path='/playground/shy-circles' exact >
          <ShyCircles />
        </Route>
      </div>
    </div>
  );
}

export default Playground;
