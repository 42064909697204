import '../styles/playground/letter-fall.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/letter-fall/art.js';

function LetterFall() {

  useEffect(() => {

    document.title = 'Letter Fall | Karan Mhatre';
    
    document.getElementById('word').value = "CREATE";
    
    Art.init();

    document.getElementById('clearBtn').addEventListener('click', () => {
      Art.cleanCanvas();
      Art.word = document.getElementById('word').value.split('');
      Art.customDesign();
    });

    document.getElementById('genBtn').addEventListener('click', () => {
      Art.cleanCanvas();
      Art.word = document.getElementById('word').value.split('');
      Art.createDesign();
      // Art.customDesign();
    });

    document.getElementById('downloadBtn').addEventListener('click', () => {
      Art.downloadImage();
    });
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="letter-fall-container art-container">
      <div className="art">
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / letter fall</h3>
        <p>Generating letter trails to connect the randomly defined points in space. <br></br><br></br>The generation tries to maintain the order of the letters in the word and keep the creation within bounds of the canvas. <br></br><br></br>Draw your own typography poster. The letter will change after 4 segments are completed. Play around, you'll get the hang of it. </p>
        <ul className="options">
          <li><input type="text" name="" id="word" maxLength="6"/></li>
          <li><button id="genBtn">Generate Random</button></li>
          <li><button id="clearBtn">Custom Draw</button></li>  
          <li><button id="downloadBtn">Download</button></li>      
        </ul>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LetterFall;
