import './App.scss';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';

// Utils
import { useEffect } from 'react';
import { NavLink } from "react-router-dom";

function DesignItem(props) {

  useEffect(() => {

  });

  return (
    <div className="design-item" key={props.id}>
      <NavLink to={`/design/${props.category}/${props.id}`}>
        <div className="media-container">
          {props.type === "img" && 
            // <Image publicId={props.thumbnail} >
              // <Transformation width="600" crop="scale" />
            // </Image>
            <img src={ "https://res.cloudinary.com/dgksx9vlc/image/upload/c_scale,w_600"+props.thumbnail } alt=""/>  
          }

          {props.type === "vid" && 
            <video autoPlay muted loop>
              <source src={props.thumbnail} type="video/mp4" />
            </video>
          }
        </div>
        <div className="info"><span> {props.name} </span><span>{props.year}</span></div>
      </NavLink>
    </div>
  );
}

export default DesignItem;