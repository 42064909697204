import '../styles/playground/entangle.scss'
import paper from 'paper'
import { useEffect } from 'react';
import Art from '../three-js-projects/entanglement/art.js';

function Entanglement() {

  useEffect(() => {

    document.title = 'Entanglement | Karan Mhatre';

    Art.init();

    document.getElementById('genBtn').addEventListener('click', () => {
      Art.cleanCanvas();
      Art.addBg();
      Art.addText();
      Art.renderSingleArt(0,0, Art.sizes.width, Art.sizes.height);
    });
  
    document.getElementById('genManyBtn').addEventListener('click', () => {
      Art.cleanCanvas();
      Art.addBg();
      Art.addText();
      Art.drawManyArtworks();
    });

    document.getElementById('downloadBtn').addEventListener('click', () => {
      Art.downloadImage();
    });
    
    return () => {
      Art.cleanCanvas();
    };

  })

  return (
    <div className="entanglement-container art-container">
      <div className="art">
        {/* <h2>having no destination<br/>I am never lost.</h2> */}
        <canvas id="paperCanvas" ></canvas>
      </div>
      <div className="info-box">
        <h3>gen-art / entanglements</h3>
        <p>We all go from the same start point to the same end point. Everything in the middle is different and unique.<br></br><br></br>
        Start from point A. Pick random points on the way, and end at point B. While picking the random point, make sure that the point is lower than the previous one.
        Randomize the number of lines, and the number of points between point A and B.<br></br><br></br>
        Each piece is unique and new.
        </p>
        <ul className="options">
          <li><button id="genBtn">Generate Single</button></li>
          <li><button id="genManyBtn">Generate Many</button></li>
          <li><button id="downloadBtn">Download</button></li>
        </ul>
        <div className="trademark">
          <ul>
            {/* <li>
              <a href="#">code and process on GitHub</a>
            </li> */}
            <li><a href="https://karanmhatre.com" target="blank">Made by karanmhatre.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Entanglement;
