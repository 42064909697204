import paper from 'paper'
import * as Tone from 'tone'
import gsap from 'gsap'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getRandomColor() {
    return this.colors[this.getRandomInt(0,this.colors.length-1)];
  }

  generateRandomLoop() {

    const scales = ["A","B","C","D","E","F","G"];
    const noOfBeats = this.getRandomInt(1,6);
    const scale = scales[this.getRandomInt(0, scales.length-1)];
    const notes = [];

    const freq = this.getRandomInt(1,16);

    const synths = [
      {
        tone: new Tone.MonoSynth().toDestination(),
        color: '#F30908'
      },
      // {
      //   tone: new Tone.MetalSynth().toDestination(),
      //   color: 'blue'
      // },
      {
        tone: new Tone.FMSynth().toDestination(),
        color: '#E5E509'
      },
      {
        tone: new Tone.MembraneSynth().toDestination(),
        color: '#0780FF'
      }
    ];

    const synth = synths[this.getRandomInt(0,synths.length-1)];
    
    for(let i=0; i<noOfBeats; i++) {
      notes.push(scale+this.getRandomInt(1,3));
    }

    const beat = new Tone.Sequence((time, note) => {
      synth.tone.triggerAttackRelease(note, "10hz", time);
      },
      notes,
      freq+"n"
    );

    return {beat:beat, color: synth.color, freq: freq};
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');    
    
    this.canvas.style.height = this.canvas.clientWidth;
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];
    this.start = false;

    this.spasm = false;

    paper.setup(this.canvas);
    this.setup();
    paper.view.draw();
    this.draw();
  }

  draw() {
    paper.view.onFrame = (e) => {
      if(this.spasm) {
        var ran = Math.abs(Math.sin(e.time*10))*50;
        document.getElementById('color-beats-container').style.backgroundColor = "hsl("+ ran +", 100%, 50%)";
        this.raster.position.y += Math.sin(e.time)*0.5;
      }
      else {
        document.getElementById('color-beats-container').style.backgroundColor = "#fff";
      }

      for(let i =0 ; i<this.beats.length; i++) {
        if(this.beats[i].status == true) {
          this.beats[i].btn.fillColor = '#000';
        }
      }
    }
  }

  switch() {
    if(this.start == false) {
      this.start = true;
      Tone.start();
      Tone.Transport.start(0);
    }
    else {
      this.start = false;
      Tone.Transport.stop();
    }
  }

  beatSwtich(e, index) {

    if(this.start == false) {
      this.start = true;
      Tone.start();
      Tone.Transport.start(0);
    }

    if(this.beats[index].status == false) {
      this.beats[index].loop.start();
      // this.beats[index].btn.fillColor = 'white';
      this.beats[index].status = true;
    }
    else {
      e.target.fillColor = this.beats[index].color;
      this.beats[index].btn.fillColor = this.beats[index].color;
      this.beats[index].loop.stop();
      this.beats[index].status = false;
    }

    var counter = 0;

    for(let i =0 ; i<this.beats.length; i++) {
      if(this.beats[i].status == true) {
        counter++;
      }
    }

    if(counter > 1) {
      this.spasm = true;
    }
    else {
      this.spasm = false;
    }
  }

  setup() {

    this.beats = [];
    this.raster = new paper.Raster('https://res.cloudinary.com/dgksx9vlc/image/upload/v1626003468/head_wwozvr.png');

    // Move the raster to the center of the view
    this.raster.position = paper.view.center;

    if(paper.view.size.width < 500) {
      this.raster.scale(0.4);
    }
    else {
      this.raster.scale(0.6);
    }

    for(let i=0; i< this.getRandomInt(10,30); i++) {
      var getSynth = this.generateRandomLoop();
      this.beats[i] = {
        status: false,
        color: getSynth.color,
        freq: getSynth.freq,
        loop: getSynth.beat,
        btn : null
      };
    }

    for(let i =0 ; i<this.beats.length; i++) {

      var whatShape = this.getRandomInt(0,2);
      if(whatShape == 0)
        var btn = new paper.Path.Circle([this.getRandomInt(50, paper.view.size.width-50), this.getRandomInt(50, paper.view.size.width-50)], this.beats[i].freq*5);
      else if(whatShape == 1) {
        var btn = new paper.Path.Rectangle([this.getRandomInt(50, paper.view.size.width-50), this.getRandomInt(50, paper.view.size.width-50)], [this.beats[i].freq*5, 10]);
        btn.rotate(45);
      }
      else 
        var btn = new paper.Path.RegularPolygon([this.getRandomInt(50, paper.view.size.width-50), this.getRandomInt(50, paper.view.size.width-50)], 6, this.beats[i].freq*5);
      
      this.beats[i].btn = btn;

      btn.fillColor = this.beats[i].color;

      btn.onClick = (e) => {
        this.beatSwtich(e, i);
      };
    }

  }

  cleanCanvas() {    

    for(let i=0;i<this.beats.length-1;i++) {
      this.beats[i].loop.stop();
      // this.beats[i].status = false;
    }

    paper.project.activeLayer.removeChildren();
  }

}