import Scene from './scene-starter-2.js'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

export default new class {

  init() {
    this.obj = Scene;

    this.obj.init(document.getElementById('rocketship'));

    // this.addGround();

    this.addLights();
    this.clock = new THREE.Clock();

    this.startTick();

    this.obj.addEvents();
    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath('/draco/')

    this.gltfLoader = new GLTFLoader();

    this.gltfLoader.setDRACOLoader(this.dracoLoader);

    this.rocket = new THREE.Group();
    
    this.gltfLoader.load('https://res.cloudinary.com/dgksx9vlc/image/upload/v1621786484/rocket-4_fk96hf.glb', (gltf) => {
      gltf.scene.receiveShadow = true;
      gltf.scene.castShadow = true;
      this.rocket.add(gltf.scene);
    });

    // this.obj.scene.position.y = -1;

    this.rocket.position.set(0,0,0);
    // this.rocket.rotation.x = .6;
    // this.rocket.rotation.z = .6;
    this.rocket.castShadow = true;

    this.obj.camera.position.set(0,0,6);
    this.obj.scene.add(this.rocket);
  }

  addLights() {
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
    this.obj.scene.add(this.ambientLight);

    this.pointLight = new THREE.PointLight(0xffffff,3, 40);
    this.pointLight2 = new THREE.PointLight(0xffffff,3, 40);
    
    this.pointLight.castShadow = true;
    this.pointLight2.castShadow = true;
    this.pointLight.position.set(-10,20,-10);
    this.pointLight2.position.set(10,20,15);
    this.obj.scene.add(this.pointLight);
    this.obj.scene.add(this.pointLight2);
  }

  startTick() {

    const tick = () => {


      setTimeout(() => {

        this.obj.render();

        const elapsedTime = this.clock.getElapsedTime();
  
        if(this.rocket !== undefined) {
          this.rocket.rotation.y = elapsedTime;
        }
   
        window.requestAnimationFrame(tick);

      }, 1000 / 60 );
        
    }

    tick();
  }

  addGround() {
    this.ground = new THREE.Mesh(
      new THREE.BoxBufferGeometry(5,.2,5),
      new THREE.MeshStandardMaterial({ color: 0xffffff })
    );
    this.ground.receiveShadow = true;
    this.obj.scene.add(this.ground);
  }

  removeAllElements() {
    this.obj.scene.clear();

    for( var i = this.obj.scene.children.length - 1; i >= 0; i--) { 
      var obj = this.obj.scene.children[i];
      
      if(obj.geometry !== undefined) {
        obj.geometry.dispose();
        obj.material.dispose();
      }
      else {
        console.log(obj);
        obj.dispose();
      }
        
    }
    
    this.obj.renderer.dispose();
  }

}