import Scene from './scene-starter.js'
import * as THREE from 'three'
import CANNON from 'cannon'

export default new class {

  init() {
    this.obj = Scene;    
    
    this.canvas = document.getElementById('letItRain');
    this.obj.init(this.canvas);

    this.obj.scene.position.set(0,-1,0);

    this.clock = new THREE.Clock();
    this.spheresArray = [];
    this.oldTime = this.clock.getElapsedTime();

    this.world = new CANNON.World();
    this.world.gravity.set(0,-9.8,0);

    const defaultMaterial = new CANNON.Material('default');
    const defaultContactMaterial = new CANNON.ContactMaterial(
      defaultMaterial,
      defaultMaterial,
      {
        friction: 0.5,
        restitution: 0.6
      }
    );

    this.world.addContactMaterial(defaultContactMaterial);
    this.world.defaultContactMaterial = defaultContactMaterial;
    // this.groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0,0,1), Math.PI/10);

    this.addGround();
    this.addBall(0.2,1, 8, 1);
    this.addLights();
    this.startTick();
  }

  startTick() {

    const tick = () => {

      setTimeout(() => {

        let newTime = this.clock.getElapsedTime();
        let deltaTime = newTime - this.oldTime;
        
        this.oldTime = newTime;
        
        window.requestAnimationFrame(tick);

        this.obj.render();

        this.world.step(1/60, deltaTime, 3);

        for(let i = 0; i < this.spheresArray.length; i++) {
          this.spheresArray[i][0].position.copy(this.spheresArray[i][1].position);
        }
      }, 1000 / 60 );

    }

    tick();
  }

  addGround() {
    this.ground = new THREE.Mesh(
      new THREE.BoxBufferGeometry(5,.2,5),
      new THREE.MeshStandardMaterial({ color: 0xdddddd })
    );

    // this.ground.rotation.z = Math.PI/10;
    this.ground.receiveShadow = true;
    this.obj.scene.add(this.ground);
    
    this.groundShape = new CANNON.Box(new CANNON.Vec3(2.5,.1,2.5));
    this.groundBody = new CANNON.Body({
      mass: 0,
      position: new CANNON.Vec3(0,0,0),
      shape: this.groundShape
    });

    this.playCollideAudio = (collision) => {
      this.audio = new Audio('https://res.cloudinary.com/dgksx9vlc/video/upload/v1621700967/hard_cm61ot.wav');
      let velocity = collision.contact.getImpactVelocityAlongNormal();
      this.audio.volume = Math.random();
      if(velocity > 1) {
        this.audio.currentTime = 0;
        // this.audio.play();
      }
    }

    this.groundBody.addEventListener('collide', this.playCollideAudio);
    this.world.addBody(this.groundBody);
  }

  addBall(radius, x,y,z) {
    this.ball = new THREE.Mesh(
      new THREE.SphereBufferGeometry(radius, 30, 30),
      new THREE.MeshStandardMaterial({ color: 0xf2f2f2 })
    );

    this.ball.castShadow = true;

    this.ball.position.set(x,y,z);
    this.obj.scene.add(this.ball);

    this.sphereShape = new CANNON.Sphere(radius);
    this.sphereBody = new CANNON.Body({
      mass: 2,
      shape: this.sphereShape,
      position: new CANNON.Vec3(x, y, z)
    });

    const obj = [this.ball, this.sphereBody];

    this.spheresArray.push(obj);

    // this.sphereBody.applyLocalForce(new CANNON.Vec3(-100,0,0), new CANNON.Vec3(0,0,0));

    this.world.addBody(this.sphereBody)
  }

  addLights() {
    this.ambLight = new THREE.AmbientLight(0xffffff, 0.1);
    this.light = new THREE.SpotLight(0xffffff, 1, 20);
    this.light.castShadow = true;
    this.obj.scene.add(this.ambLight);
    this.obj.scene.add(this.light);
    this.light.position.set(3,1,3);
  }

}

