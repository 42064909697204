import paper from 'paper'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');
    
    this.canvas.style.height = (this.canvas.clientWidth+100) + "px";
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];
    // this.colors = ['#E9BA40', '#E0323D', '#4298E0'];
    
    this.sizes = {
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight
    }

    this.paths = [];
    
    paper.setup(this.canvas);
    
    this.drawWave();
    this.animate();

    paper.view.draw();

    this.addBg();

    paper.view.onClick = (event) => {
      this.drawWave(event.point.x, event.point.y, this.getRandomInt(100,300), this.getRandomInt(2,50));
    }
  }


  addBg() {
    var rect = new paper.Path.Rectangle({
      point: [0, 0],
      size: [paper.view.size.width, paper.view.size.height],
    });
    rect.sendToBack();
    rect.fillColor = '#000';
  }

  randomColor() {
    return this.colors[this.getRandomInt(0,this.colors.length-1)];
  }

  drawWave(x=300,y=300, graphSize=200, points=5) {

    var path = new paper.Path();
    path.fillColor = this.randomColor();
    // path.strokeColor = 'black';
	  // path.strokeWidth = 2;
    // path.fillColor = {
    //   gradient: {
    //       stops: ['yellow', 'red', 'blue']
    //   },
    //   origin: [0,0],
    //   destination: [1000,1000]
    // };
    
    // path.opacity = 0.8;
    
    path.segments = [];
    path.add(new paper.Point(x, y+50));
    
    for (var i = 0; i <= points; i++) {
      var point = new paper.Point(x+(i*graphSize/points), y);
      path.add(point);
    }
    path.add(new paper.Point(x+graphSize, y+50));
    path.smooth({ type: 'continuous' });

    path.position.x = x;
    path.position.y = y;

    this.paths.push(path);
  }

  animate() {
      
      setTimeout(() => {

        for(let j=0;j <this.paths.length; j++) {
          var actualY = this.paths[j].segments[0].point.y;

          for (var i = 1; i <= this.paths[j].segments.length-2; i++) {
            var sinSeed = Date.now()+(i + (j*20) + i % 10) * 100;
		        var sinHeight = Math.sin(sinSeed / 200) * 50;

            // var height = Math.sin((Date.now()*0.01));
            this.paths[j].segments[i].point.y = sinHeight+actualY-50;
          }
        }

        requestAnimationFrame(() => {
          this.animate();
        });
  
      }, 1000/60);
    }


  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

  downloadImage() {
    var image = this.canvas.toDataURL();
    var aDownloadLink = document.createElement('a');

    aDownloadLink.download = 'poster-karanmhatre-dot-com-'+Date.now()+'.png';

    aDownloadLink.href = image;

    aDownloadLink.click();
  }
}