import paper from 'paper'
import dat from 'dat.gui'
import tooloud from 'tooloud'

export default new class {

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getRandomColor() {
    return this.colors[this.getRandomInt(0,this.colors.length-1)];
  }

  init() {
    this.canvas = document.getElementById('paperCanvas');
    this.canvas.style.height = this.canvas.clientWidth+"px";

    this.points = [];

    this.gui = new dat.GUI({
      closed: false,
      closeOnTop: false
    }); 
    
    const customContainer = document.querySelector('.gui-controls');
    customContainer.appendChild(this.gui.domElement);

    tooloud.Perlin.setSeed(Math.floor(Math.random() * 10000));

    this.width = this.canvas.clientWidth;
    this.height = this.canvas.clientHeight;

    this.params = {
      density: 30,
      mult: 0.005,
      reDraw : () => {
        this.time = Date.now();
        this.cleanCanvas();
        this.setup();
      },
      extend : () => {
        this.time = Date.now();
      },
      width: 10
    };

    this.gui.add(this.params, 'density', 10, 60, 5);
    this.gui.add(this.params, 'width', 2, 20, 1);
    this.gui.add(this.params, 'mult', 0.001, 0.09, 0.005);
    this.gui.add(this.params, 'reDraw');
    this.gui.add(this.params, 'extend');
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];

    paper.setup(this.canvas);
    this.setup();
    paper.view.draw();
    
    this.animate = true;
    this.draw();
  }


  addBg() {
    var rect = new paper.Path.Rectangle({
      point: [0, 0],
      size: [paper.view.size.width, paper.view.size.height],
    });
    rect.sendToBack();
    rect.fillColor = '#000';
  }


  setup() {
    this.addBg();
    this.space = this.width/this.params.density;
    this.time = Date.now();

    for(let i=0;i<this.width;i+=this.space) {
      for(let j=-1*this.space;j<this.height;j+=this.space) {
        var point = new paper.Path();
        point.moveTo(new paper.Point(i,j));
        point.strokeColor = this.getRandomColor();
        point.strokeWidth = this.params.width;
        point.opacity = 1;
        this.points.push(point);
      } 
    }

  }

  draw() {

    console.log(this.time);

    this.newTime = Date.now();

      this.animate = setTimeout(() => { 

        if((this.newTime - this.time) < 4000) {

          for(let i=0; i<this.points.length; i++) {
            var n = tooloud.Perlin.noise(this.points[i].lastSegment.point.x*this.params.mult, this.points[i].lastSegment.point.y*this.params.mult, 0);
            var x = this.points[i].lastSegment.point.x + Math.sin(n);
            var y = this.points[i].lastSegment.point.y + Math.cos(n);
    
            // var circle = new paper.Path.Circle(new paper.Point(x,y), 5);
            // circle.fillColor = '#000';
    
            this.points[i].add(new paper.Point(x,y));
            // this.points[i].simplify();
          }
        }
  
        requestAnimationFrame(() => { this.draw() });
  
      }, 1000/30);
    
  }

  // draw() {
  //   paper.view.onFrame = (event) => {

      
  //   };   
  // }

  downloadImage() {
    var image = this.canvas.toDataURL();
    var aDownloadLink = document.createElement('a');

    aDownloadLink.download = 'poster-karanmhatre-dot-com-'+Date.now()+'.png';

    aDownloadLink.href = image;

    aDownloadLink.click();
  }

  cleanCanvas() {    
    // clearTimeout(this.animate);
    paper.project.activeLayer.removeChildren();
  }

}