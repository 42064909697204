import '../styles/playground/spaceTravel.scss'
import Art from '../three-js-projects/space-travel/art.js';
import { useEffect } from 'react';

function SpaceTravel() {

  useEffect(() => {
    Art.init();

    return () => {
      // Art.renderer.dispose();
    };
  })

  return (
    <div className="space-travel-container">
      <h1>Space</h1>
      <canvas id="spaceTravel"></canvas>
    </div>
  );
}

export default SpaceTravel;
