import paper from 'paper'

export default new class {

  init() {
    this.canvas = document.getElementById('paperCanvas');
    this.audio = new Audio('https://www.bensound.com/bensound-music/bensound-slowmotion.mp3');
  
    this.sizes = {
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight
    }  
    
    this.xLimit = Math.floor(this.canvas.clientWidth/10);
    this.yLimit = Math.floor(this.canvas.clientHeight/30);
    // this.xLimit = 98;
    // this.yLimit = 32;
  
    this.isAutoPlay = false;
    this.isAnimate = false;

    this.step = {
      x: (this.sizes.width/this.xLimit),
      y: (this.sizes.height/this.yLimit)
    }

    // if (this.sizes.width < 600) {

    //   this.step = {
    //     x: (this.sizes.width/this.xLimit)*5,
    //     y: (this.sizes.height/this.xLimit)*5
    //   }
    // }
    // else {
    //   this.step = {
    //     x: this.canvas.clientWidth/this.xLimit,
    //     y: (this.canvas.clientHeight/this.xLimit)*3
    //   }
    // }
    
    this.count = {
      x: 2,
      y: 1
    };
    
    this.colors = ['#FB9300', '#F54748', '#F5E6CA', '#343F56'];
    this.last_is_rect = false;
    this.last_is_circle = false;
    
    paper.setup(this.canvas);
    this.render();
  }
    
  getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
  drawRect() {
    var length = this.getRandomInt(5,40)*10;
  
    if(length/10 > this.xLimit-this.count.x) {
      length = (this.xLimit-this.count.x) * 10;
    }
  
    var pos_one = new paper.Point(this.step.x*this.count.x, (this.step.y*this.count.y)-10);
    var pos_two = new paper.Point((this.step.x*this.count.x)+length, (this.step.y*this.count.y)+10);
  
    var rectangle = new paper.Rectangle(pos_one, pos_two);
  
    var radius = new paper.Size(10, 10);
    var path = new paper.Path.Rectangle(rectangle, radius);
  
    path.fillColor = this.colors[this.getRandomInt(0,this.colors.length-1)];
    this.last_is_rect = true;
    this.last_is_circle = false;
  }
    
  updateCounter() {
    if(this.count.x == this.xLimit) {
      this.count.x = 2;
      this.count.y++;
    }
    else {
      this.count.x++;
    }
  }
    
  drawPoint() {
    var myCircle = new paper.Path.Circle(new paper.Point(this.step.x*this.count.x, this.step.y*this.count.y), 2);
    myCircle.fillColor = this.colors[this.getRandomInt(0,this.colors.length-1)];
    this.last_is_rect = false;
    this.last_is_circle = false;
  }
    
  drawCircle () {
    var myCircle = new paper.Path.Circle(new paper.Point(this.step.x*this.count.x, this.step.y*this.count.y), 10);
    myCircle.fillColor = this.colors[this.getRandomInt(0,this.colors.length-1)];
  
    this.last_is_rect = false;
    this.last_is_circle = true;
  }
    
  render() {
  
    paper.project.activeLayer.removeChildren();

    this.addBg();
    this.count.x = 2;
    this.count.y = 1;
  
    while(this.count.y < this.yLimit) {
  
      var random = Math.random();
  
      if(random > 0.95 && !this.last_is_circle) {
        this.drawCircle();
      } 
      else if(this.count.x < this.xLimit && !this.last_is_rect && this.count.y%2==1) {
  
        if(random < 0.05)
          this.drawRect();
        else
          this.drawPoint();
      }
      else {
        this.drawPoint();
      }
    
      this.updateCounter();
    }
  
    paper.view.draw();
  }
    
  repeatRender() {
      
    if(this.isAutoPlay) {
      setTimeout(() => {
        this.render();
        window.requestAnimationFrame(this.repeatRender);
      }, 500);
    }
  }
  
  tick() {

    // console.log(this.isAnimate);
  
    if(this.isAnimate && this.count.y < this.yLimit) {
  
      var random = Math.random();
  
      // draw rectangle if last is not a rectangle and it is not the end of the row
  
      if(random > 0.95 && !this.last_is_circle) {
        this.drawCircle();
      } 
      else if(this.count.x < 98 && !this.last_is_rect && this.count.y%2==1) {
  
        if(random < 0.05)
          this.drawRect();
        else
          this.drawPoint();
      }
      else {
        this.drawPoint();
      }
  
      this.updateCounter();
  
      paper.view.draw();
  
      window.requestAnimationFrame(() => { this.tick() });
    }
  } 

  cleanCanvas() {    
    paper.project.activeLayer.removeChildren();
  }

  addBg() {
    var rect = new paper.Path.Rectangle({
      point: [0, 0],
      size: [paper.view.size.width, paper.view.size.height],
    });
    rect.sendToBack();
    rect.fillColor = '#000';
  }



  downloadImage() {
    var image = this.canvas.toDataURL();
    var aDownloadLink = document.createElement('a');

    aDownloadLink.download = 'poster-karanmhatre-dot-com-'+Date.now()+'.png';

    aDownloadLink.href = image;

    aDownloadLink.click();
  }
}