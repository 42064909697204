import '../styles/Footer.scss';

function Footer() {

  return (
    <footer className="container" data-scroll-section>
      <div className="footer-content">
        <div className="content">
          <h2>Let's connect</h2>
          <ul className="connect-links">
            <li><a alt="Karan Mhatre Email ID" href="mailto:me@karanmhatre.com">me@karanmhatre.com</a></li>
            <li><a alt="Karan on Linkedin" rel="noreferrer" href="https://www.linkedin.com/in/karanmhatre/" target="_blank"><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1623341262/Website/linkedin_awygii.svg" alt=""/> Resume and work experience</a></li>
            <li><a alt="Karan on Instagram" rel="noreferrer" href="https://www.instagram.com/onesquarekaran/" target="_blank"><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1623341262/Website/instagram_xunhmm.svg" alt=""/> 220 paintings</a></li>
            <li><a alt="Karan on Github" rel="noreferrer" href="https://github.com/karanmhatre1" target="_blank"><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1623341262/Website/github_sqw2he.svg" alt=""/> 15 open source projects</a></li>
            <li><a alt="Karan on Medium" rel="noreferrer" href="https://medium.com/@me_12358" target="_blank"><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1623341262/Website/medium_en6czv.svg" alt=""/> 6 design and product management articles</a></li>
          </ul>
        </div>
        <div><img src="https://res.cloudinary.com/dgksx9vlc/image/upload/v1623313125/footer_zj17u2.png" alt=""/></div>
      </div>
    </footer>
  );
}

export default Footer;